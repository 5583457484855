import * as React from "react";
//@ts-ignore
import {Accordion, Col, useAccordionToggle} from "react-bootstrap";

export interface IPlusMinusAccordionProps {
	header: string;
	children: JSX.Element | JSX.Element[] | string;
	eventKey: string;
	runUpdate: (eventKey: string) => void;
	activeKey: string;
}

const PlusMinusAccordion: React.FC<IPlusMinusAccordionProps> = (props: IPlusMinusAccordionProps): JSX.Element => {

	const {header, children, eventKey, runUpdate, activeKey} = props;

	const decoratedOnClick = useAccordionToggle(eventKey, () => {
		if (eventKey === activeKey) {
			runUpdate("");
		} else {
			runUpdate(eventKey);
		}
	});

	return (
		<div className={`plus-minus-accordion active-${eventKey === activeKey}`}>
			<Col
				onClick={decoratedOnClick}
				className={"accordion-header"}
			>
				<strong>{header}</strong>
			</Col>
			<Accordion.Collapse className="border-left-0 border-right-0 border-top-0 border-bottom-1"
								eventKey={eventKey}>
				<div className={"py-3"}>
					{children}
				</div>
			</Accordion.Collapse>
		</div>
	);
};


export default PlusMinusAccordion;
