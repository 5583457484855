

import WhatIs1 from "../static/Home/what-if-1.png";
import WhatIs3 from "../static/Home/what-if-3.png";
import WhatIs2 from "../static/Home/what-if-2.png";


export interface IWhatIsPixelMoments {
	image: string;
	title: string;
	description: string;
	clname: string
}

const whatIsPixelMoments: IWhatIsPixelMoments[] = [
	{
		title: "Making Philanthropy Easy",
		description: "For as little as $5 and 5 minutes, make a difference for a cause you care about. Your donation helps St. Paul’s Foundation fund mental health resources, right in your community.",
		image: WhatIs1,
		clname: "WhatIs1"
	},
	{
		title: "Supporting the St. Paul’s Foundation",
		description: "Have a direct impact on St. Paul’s Hospital, and all Providence Health Care hospitals and long-term care homes. Donations to St. Paul’s Foundation have a tangible impact on resources for patients and their families.",
		image: WhatIs3,
		clname: "WhatIs3"
	},
	{
		title: "Visit Us at the Vancouver Mural Festival’s Winter Arts Fest",
		description: "This Year Pixel Moments is teaming up with the Vancouver Mural Festival. Visit the Pixel Moments booth at the VMF Winter Arts Festival from February 11th to 27th in front of the located at šxʷƛ̓ənəq Xwtl'e7énḵ Square on the north side of the Vancouver Art Gallery.",
		image: WhatIs2,
		clname: "WhatIs2"
	},
	
];

export default whatIsPixelMoments;
