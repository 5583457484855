import * as React from "react";
import {RefObject, useEffect} from "react";

const InstagramWidget: React.FC = (): JSX.Element => {
	const refWrapper: RefObject<HTMLDivElement> = React.createRef();

	useEffect(() => {
		if (refWrapper.current) {
			const script = document.createElement("script");
			script.src = "https://apps.elfsight.com/p/platform.js";
			script.async = true;
			refWrapper.current.appendChild(script);
		}

	}, [refWrapper]);

	return (
		<div>
			<div className="elfsight-app-481eccae-11fd-488b-b439-1a8518b4b380"/>
			<div ref={refWrapper}/>
		</div>
	);
}

export default InstagramWidget;
