import * as React from "react";
import {Col, Container, Row} from "react-bootstrap";


import WhatIs1 from "../static/Home/what-if-1.png";
import WhatIs3 from "../static/Home/what-if-3.png";
import WhatIs2 from "../static/Home/what-if-2.png";

interface donationImageElement {
	header: string;
	image: string;
	imageContainerClasses?: string;
	imageStyle?: object;
	alt: string;
	description: string;
	descriptionClasses?: string,
	iconClass?: string,
}

const donationImageArray: donationImageElement[] = [
	{
		header: "Making Philanthropy Easy",
		image: WhatIs1,
		imageContainerClasses: "donate-step__1",
		alt: "step 1",
		description: "For as little as $5 and 5 minutes, make a difference for a cause you care about. Your donation helps St. Paul’s Foundation fund mental health resources, right in your community.",
		descriptionClasses: "py-md-5",
		iconClass: "icon1"
	},
	{
		header: "Supporting the St. Paul’s Foundation",
		image: WhatIs3,
		imageContainerClasses: "donate-step__2",
		alt: "step 2",
		description: "Have a direct impact on St. Paul’s Hospital, and all Providence Health Care hospitals and long-term care homes. Donations to St. Paul’s Foundation have a tangible impact on resources for patients and their families.",
		descriptionClasses: "py-md-5",
		iconClass: "icon2"
	},
	{
		header: "Partnering with VMF Winter Arts",
		image: WhatIs2,
		imageContainerClasses: "donate-step__3",
		alt: "step 3",
		description: "This Year Pixel Moments is teaming up with VMF Winter Arts. Visit the Pixel Moments booth at the VMF Winter Arts Festival from February 11th to 27th in front of the located at šxʷƛ̓ənəq Xwtl'e7énḵ Square on the north side of the Vancouver Art Gallery.",
		descriptionClasses: "pt-md-5",
		iconClass: "icon3"
	},
]


function renderElement(content: any, index: number): JSX.Element {
	return (
		<Col xs={12} md={4} key={`donation-step-${index}`} className="py-3">
			
			<div className="card-cust">

            <div className="slide slide1">

                <div className="content">

                    <div className={'icon ' +content.iconClass}>

					<img style={content.imageStyle} src={content.image} alt={content.alt}/>

                    </div>

                </div>

            </div>

            <div className="slide slide2">

                <div className="content">

                    <h3 className="what-is-header-d">

					{content.header}

                    </h3>

                    <p className="what-is-text-d">{content.description}</p>

                </div>

            </div>

        </div>

        
			

			
		</Col>
	);
}

const WhatIsPixelMoments: React.FC = (): JSX.Element => {

	return (
		<Container>

			<div className="left-header pm-header">
				<h2 className="">What is Pixel Moments</h2>
				
			</div>

			<Container className="text-center steps-container-2-">
				<Row>
					{donationImageArray.map(renderElement)}
				</Row>
			</Container>
			
		</Container>
	);
}

WhatIsPixelMoments.defaultProps = {};

export default WhatIsPixelMoments;
