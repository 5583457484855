import * as React from "react";


const PrivacyPolicyContent: React.FC = (): JSX.Element => {

	return (

<div className="privacy-policy-content">

			<h1 className=" mb-5">Terms and Privacy</h1>
			

			<div className="pp-block ">
			
			
			
			<div>
			<p>By using the Pixel Moments ("Website" and "Applications"), you represent and warrant that you have carefully reviewed this Image Consent and Release Form before signing below, and understand all its contents, provisions, meaning and impact, and agree that it is binding on you, your heirs, executors, administrators and assigns. You voluntarily and irrevocably give your consent and agree to this release and waiver.</p>
			</div>
			

			
			
			
			</div>

			<div className="pp-block pp-block-1">
			
			<h3>
			Transfer of Rights
			</h3>
			
			<div>
			<p>
				
			By uploading your picture you hereby agree to give the St. Paul’s Foundation ("SPF"), its Board of Governors, agents, officers, employees, agents, assigns and legal representatives the irrevocable right to use, publish and display or permit, and release and discharge SPF, its Board of Governors, agents, officers and employees, assigns and legal representatives from any and all claims and demands arising out of or in connection with, the use, publication and display of the photograph or image or likeness you are submitting with or following my Pixel Moments donation to the Pixel Moments project (the "Project") web site (collectively, "My Image") at SPF's sole discretion, in all forms and manner, including but not limited to use in the Project's digital photo-mosaic art mural ("Mural") (by artist Andrea Wan (the "Artist")) by SPF and/or Artist, publication by SPF and/or Artist on SPF-owned and non-SPF-owned property, print media, digital media, the Internet, social media sites, the SPF and other websites, online marketing materials and graphics, offline marketing collateral, teaser commercial videos, final Mural production, SPF newsletters and updates, future digital screens at new St. Paul’s campus, to social media influencers selected by SPF who SPF provides My Image to for use on their blogs or social media, and media broadcasts, in connection with activities relating to SPF and the Project and the Mural, such as promoting, publicizing, or explaining SPF or its activities, for fundraising related purposes or for other consistent purposes, without further notification to you.
			</p>
			</div>
			

			
			
			
			</div>

			<div className="pp-block pp-block-1">
			
			

			<h3>
			Publication & Waiver
			</h3>
			<div>
			<p>
				
			You hereby forever waive any right to inspect or approve the use or publication of My Image and all copyrights, intellectual property rights, privacy rights and any other rights that you have in My Image for use for any of the purposes set out above.
			</p>
			<p>
				
			You represent and warrant that you obtained consent from any other person whose image or likeness appears in My Image to the use of that person's image or likeness for any of the purposes described herein, and that you will fully indemnify SPF [et al.] for any and all claims and demands arising out of or concerning the use, publication and display of that person's image or likeness as it appears in My Image.
			</p>
			<p>
			You acknowledge that you are not entitled to and shall not seek any compensation fees or royalties of any kind, arising in any way from my consent of the use of My Image.
			</p>
			<p>You understand that SPF cannot control unauthorized use of My Image by persons not associated with SPF once My Image has been published.</p>
			
			</div>
			
			</div>

			<div className="pp-block pp-block-1">
			
			

			<h3>
			Personal Information
			</h3>
			<div>
			<p>
				
			Pursuant to British Columbia’s Personal Information Protection Act ("PIPA"), You consent to the collection of your personal information in the form of My Images by SPF, its employees, agents and representatives, to be used for the purposes and disclosed to third parties as described above.
			</p>
			<p>
				
			You may only upload My Images if you are 19 years of age or older.
			</p>
			
			</div>
			
			</div>

			<div className="pp-block">
			
			

			<h3>
			Privacy Notice
			</h3>
			<div>
			<p>
				
			Personal information in the form of My Images are collected by SPF in compliance with PIPA and the St Paul’s Foundation Privacy policy which can be found in full form here: <a href="https://helpstpauls.com/privacy-policy?_ga=2.171994279.500235185.1610422670-1585421200.1610422670"> https://helpstpauls.com/privacy-policy?_ga=2.171994279.500235185.1610422670-1585421200.1610422670 </a> .
			</p>
			<p>
				
			If you have any questions about the collection of your personal information by SPF, you can contact SPF’s privacy officer Johann Boulter.
			</p>

			
				<p>
			Email: <a href={"mailto:jboulter@providencehealth.bc.ca"} className="">
			jboulter@providencehealth.bc.ca
						</a> .
			</p>

			<p>
			Telephone: <a href="tel:604-806-8923"> 604-806-8923. </a> 
			</p>

			<p>
			Mailing address: Suite 178, 1081 Burrard Street, Vancouver, B.C. V6Z 1Y6


			</p>
			
			</div>
			
			</div>
			
		</div>
	);
}


export default PrivacyPolicyContent;
