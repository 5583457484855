import * as React from "react";
import {Container} from "react-bootstrap";
import TermsAndConditionsContent from "../components/TermsAndConditionsContent";

const TermsAndConditions: React.FC = (): JSX.Element => {

	return (
		<main className="basic-page bg-white">

			<section className="basic-page--hero"/>

			<section className="basic-page--content">

				<div className="absolute-pixel absolute-pixel--1">
					<div className="pixel pixel--1"/>
					<div className="pixel pixel--2"/>
					<div className="pixel pixel--3"/>
				</div>

				<Container>
					<TermsAndConditionsContent/>
				</Container>
			</section>
		</main>
	);
}


export default TermsAndConditions;
