import * as React from "react";
import {Dispatch, SetStateAction, useState} from "react";
import {Accordion, Col, Container, Row} from "react-bootstrap";
import PlusMinusAccordion from "./PlusMinusAccordian";
import {RouteComponentProps, withRouter, Link} from "react-router-dom";
import {EModalContent} from "../pages/Stories";


import twitter from "../static/social/twitter.svg";
import facebook from "../static/social/facebook.svg";
import email from "../static/social/email.svg";
import instagram from "../static/social/instagram.svg";


interface IFaqComponentProps extends RouteComponentProps {
	setModalContent?: Dispatch<SetStateAction<EModalContent>>;
}

const FaqComponent: React.FC<IFaqComponentProps> = (props: IFaqComponentProps): JSX.Element => {
	// const {setModalContent, history} = props;
	const [accordionActive, setAccordionActive] = useState<string>("");

	// function clickTermsConditions() {
	// 	if (setModalContent) {
	// 		setModalContent(EModalContent.TERMS_CONDITIONS);
	// 	} else {
	// 		history.push("/terms-and-conditions");
	// 	}
	// }

	// function clickPrivacyPolicy() {
	// 	if (setModalContent) {
	// 		setModalContent(EModalContent.PRIVACY_POLICY);
	// 	} else {
	// 		history.push("/terms-and-conditions");
	// 	}
	// }

	return (
		<Container>
			<Row>
				<Col xs={12} className=" ">
					<h1 className="faq-component--header">Frequently Asked Questions</h1>
				</Col>
				<Col xs={12} className="py-4 py-md-0 accordian-top">
					<Accordion >
						<PlusMinusAccordion header="What is Pixel Moments?" eventKey="0"
						                    activeKey={accordionActive} runUpdate={setAccordionActive}>
							<p>Pixel Moments is an initiative started by the Future Leaders committee at the St. Paul's Hospital Foundation. The goal is to promote awareness about mental health and to make charitable giving more accessible to individuals of all ages. It doesn't matter who you are, we can all spare at least a dollar to support mental wellness programs in BC. Using digital media and art, the Future Leaders committee is introducing a new way of fundraising through microdonations to create a large impact collectively one pixel at a time.</p>
						</PlusMinusAccordion>
						<PlusMinusAccordion header="How much is each pixel?" eventKey="1" activeKey={accordionActive}
						                    runUpdate={setAccordionActive}>
							<p>The minimum donation is $5 for 1 upload.</p>
						</PlusMinusAccordion>
						<PlusMinusAccordion header="What is the minimum and maximum size of an image I can upload?"
						                    eventKey="2" activeKey={accordionActive}
						                    runUpdate={setAccordionActive}>
							<p>Each donor will receive a 3" W x 3" H pixel at 150DPI (Large Post-It Note Size), the minimum resolution of each pixel would be 450px * 450px. The maximum file we can accept is 5MB. If your file is too large or too small we may not be able to use it.</p>
						</PlusMinusAccordion>
						<PlusMinusAccordion header="What does Pixel mean?" eventKey="3"
						                    activeKey={accordionActive}
						                    runUpdate={setAccordionActive}>
							<p>A pixel is the smallest unit of a digital image or graphic that can be displayed and represented on a digital display device.</p>

					<p>A pixel is the basic logical unit in digital graphics. Pixels are combined to form a complete image, video, text, or any visible thing on a digital display.</p>

					<p>A pixel is also known as a picture element (pix = picture, el = element).<br/>
						(Source: <a href={"https://www.techopedia.com/definition/24012/pixel"} target="_blank"
						            rel="noopener noreferrer"
						            style={{wordWrap: "break-word"}}>https://www.techopedia.com/definition/24012/pixel</a>)<br/>
						For Pixel Moments, a donor can participate by uploading their photos to be used to make a photomosaic. Each photo or image uploaded is one pixel.</p>
						</PlusMinusAccordion>
						<PlusMinusAccordion header="How will my image/photo/pixel be used?" eventKey="4"
						                    activeKey={accordionActive}
						                    runUpdate={setAccordionActive}>
							<p>See intended uses.</p>
						</PlusMinusAccordion>
						<PlusMinusAccordion header="When will the photomosaic be installed?" eventKey="5"
						                    activeKey={accordionActive}
						                    runUpdate={setAccordionActive}>
							<p>Mid-March 2022. For your image to make onto the final photo mosaic mural install at our venue, your donation will have to be made by February 25, 2022. After this date, the images we receive will continue to be added to the mosaic and be displayed on our website for the rest of the year.</p>
						</PlusMinusAccordion>
						<PlusMinusAccordion header="How long will the photomosaic be available for viewing?" eventKey="6"
						                    activeKey={accordionActive}
						                    runUpdate={setAccordionActive}>
							<p>From mid-March to mid-May at 710 Granville Street, Vancouver and live on pixelmoments.org.</p>
						</PlusMinusAccordion>
						<PlusMinusAccordion header="What if I submitted an image after the February 25th, 2022 deadline?" eventKey="7"
						                    activeKey={accordionActive}
						                    runUpdate={setAccordionActive}>
							<p>Donations are welcomed throughout the year. We may keep your image and use it for future years.</p>
						</PlusMinusAccordion>
						<PlusMinusAccordion header="Can I upload a photo that does not belong to me?" eventKey="8"
						                    activeKey={accordionActive}
						                    runUpdate={setAccordionActive}>
							<p>No. By uploading your photo, you will have agreed to the <Link to="/terms-and-conditions">terms
						and conditions</Link> of Pixel Moments.</p>
						</PlusMinusAccordion>
						<PlusMinusAccordion header="How can you ensure my privacy is protected?" eventKey="9"
						                    activeKey={accordionActive}
						                    runUpdate={setAccordionActive}>
							<p>See our <Link to={"/privacy-policy"}>Privacy Policy</Link> page.</p>
						</PlusMinusAccordion>
						<PlusMinusAccordion header="Can I Volunteer with Pixel Moments?" eventKey="10"
						                    activeKey={accordionActive}
						                    runUpdate={setAccordionActive}>
							<p>Yes, Pixel Moments is always looking for energetic and creative individuals who are passionate about arts, digital media, health care, and just want to have a good time while promoting a legacy project.</p>
							
							<p>We will be on site at the Vancouver Mural Festival from February 11th to 27th, 2022 and will also be setting up on location at the London Drugs on 710 Granville Street, Vancouver (Granville Street and West Georgia Street) to promote Pixel Moments If you are interested in volunteering with Pixel Moments please contact <br/> <a href="mailto:support@pixelmoments.org">support@pixelmoments.org</a></p>
						</PlusMinusAccordion>
						<PlusMinusAccordion header="I am in. I would like to support Pixel Moments; how can I support?" eventKey="11"
						                    activeKey={accordionActive}
						                    runUpdate={setAccordionActive}>
							<p>There are many ways to support the cause. Below are some ways you can support.</p>
					<ul>
						<li>Buy a pixel</li>
						<li>Upload a pixel</li>
						<li>Share a personal story</li>
						<li>Share Pixel Moment on your social media</li>
						<li>Be a brand ambassador</li>
						<li>Be a Pixel Moments influencer</li>
						<li>Provide a testimonial of your experience at St Paul's</li>
						<li>Share the cause on your social media
							<div>
								<a
									className="btn btn--bg-accent--twitter faq--buttons--social"
									href="https://twitter.com/intent/tweet?text=Creating%20Big-Picture%20Impact,%20Together&url=https%3A%2F%2Fpixelmoments.org"
									target="_blank"
									rel="noopener noreferrer"
								>
									<img src={twitter} alt="twitter"/>
								</a>
								<a
									className="btn btn--bg-accent--facebook faq--buttons--social"
									href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fpixelmoments.org&t=Pixel%20Moments"
									target="_blank"
									rel="noopener noreferrer"
								>
									<img src={facebook} alt="facebook"/>
								</a>
								<a
									className="btn btn--bg-accent--mail faq--buttons--social"
									href="mailto:?body=We%20all%20know%20someone%20who%20has%20struggled%20with%20it.%20Maybe%20it%27s%20you.%20A%20parent%20or%20family%20member.%20A%20best%20friend.%20With%20empathy%20and%20action%2C%20let%E2%80%99s%20tackle%20this%20crisis%20together.%0D%0A%0D%0Ahttps%3A%2F%2Fpixelmoments.org/&subject=Pixel%20Moments"
								>
									<img src={email} alt="email"/>
								</a>
								<a
									className="btn btn--bg-accent--instagram faq--buttons--social"
									href="https://www.instagram.com/PixelDonate/"
									target="_blank"
									rel="noopener noreferrer"
								>
									<img src={instagram} alt="instagram"/>
								</a>
							</div>
						</li>
						<li>Volunteer at our booth at the VMF Winter Arts produced by the team behind Vancouver Mural Festival running from February 11th to 27th, 2022 or our final mural location at the London Drugs on 710 Granville Street, Vancouver (Granville Street and West Georgia Street).</li>
						<li>Help us recruit volunteers</li>
					</ul>
					<p>If you have an idea and don't see it here, feel free to contact us at <a
						href="mailto:support@pixelmoments.org">support@pixelmoments.org</a></p>
						</PlusMinusAccordion>
					</Accordion>
				</Col>
			</Row>
		</Container>
	);
}


export default withRouter(FaqComponent);
