import * as React from "react";
import {Container} from "react-bootstrap";
import {Link, useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import moment from "moment";

import fooB from "../static/foo-b.svg";
import fooW from "../static/foo-w.svg";

const Footer: React.FC = (): JSX.Element => {
	const location = useLocation();
	const [displayFooter, setDisplayFooter] = useState<boolean>(true);
	const [colorFoot, setColorFoot] = useState<string>('component');

	useEffect(() => {

		setColorFoot(location.pathname === "/about"? 'light': 'component')

		let pathname = location.pathname;
		// Remove trailing slash from pathname
		if (pathname[pathname.length - 1] === "/") {
			pathname = pathname.substring(0, pathname.length - 1);
		}
		if (pathname === "/donate" || pathname === "/pixel-upload") {
			setDisplayFooter(false);
		}
		 else {
			setDisplayFooter(true);
		}

	}, [location]);

	if (displayFooter) {
		return (
			<footer className={`${colorFoot}-footer`}>
				<div className="footer-grid">
					<div className="footer-logo1">
					<Link to="/">
						{
							(
								(location.pathname === "/about") ? (
								<img src={fooB} className="logo-foo" alt="Pixel Moments"/> ): 
								(<img src={fooW} className="logo-foo" alt="Pixel Moments"/>) 
								
							)
						}
					</Link>
						
						
							
					</div>
					
					<div className="footer-msg-1">
						Follow Us <a className="insta-link" href="https://www.instagram.com/pixeldonate/?hl=en "> @PixelDonate</a> 
					</div>
					<div className="footer-msg-2">
						 &copy;{moment().format("YYYY")} Pixel Moments
					</div>
					<div className="footer-nav">
					<ul>
						<li className={` ${(location.pathname === "/") && "is-active"}`}>
							<Link to="/">HOME</Link>
						</li>
						<li className={(location.pathname === "/about") ? "is-active" : ""}>
							<Link to="/about">ABOUT</Link>
						</li>
						<li className={(location.pathname === "/artwork") ? "is-active" : ""}>
							<Link to="/artwork">THE ART</Link>
						</li>
						<li className={`${(location.pathname === "/sponsors") && "is-active"}`}>
							<Link to="/sponsors">SPONSORS</Link>
						</li>
						<li className={`${(location.pathname === "/donate") && "is-active"}`}>
							<a href="https://secure3.convio.net/sphf/site/Donation2?df_id=2620&2620.donation=form1&mfc_pref=T">DONATE</a>
						</li>
						
						<li className={`${(location.pathname === "/privacy-policy") && "is-active"}`}>
							<Link to="/privacy-policy">TERMS AND PRIVACY</Link>
						</li>
						<li className={`${(location.pathname === "/faq") && "is-active"}`}>
							<Link to="/faq">FAQ</Link>
						</li>
						<li>
						<a href={"mailto:support@pixelmoments.org"} className="">
							CONTACT US
						</a>
						</li>
						
					</ul>
					</div>
					<div className="footer-social">
					<Container className="text-center">
						<a className="social-image-link" href="https://twitter.com/Pixel_Donate" target="_blank"
						   rel="noopener noreferrer">
							<i className="fab fa-twitter"/>
						</a>
						<a className="social-image-link" href="https://www.instagram.com/PixelDonate" target="_blank"
						   rel="noopener noreferrer">
							<i className="fab fa-instagram"/>
						</a>
						<a className="social-image-link" href="https://www.facebook.com/pixelmoments.org"
						   target="_blank" rel="noopener noreferrer">
							<i className="fab fa-facebook"/>
						</a>
					</Container>
					</div>

				</div>

				


			</footer>
		);

	} else {
		return (<React.Fragment/>);
	}

}

export default Footer;
