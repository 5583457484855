import * as React from "react";

import {Link} from "react-router-dom";

import {Col, Container, Row} from "react-bootstrap";

import futureLeaders from "../static/future-leaders.jpg"

import Sec1r from "../static/About/sec1-1.svg";


import futureLtop from "../static/Home/sec-3-top.svg";
import futureLbtm from "../static/About/flbtm.svg";

import pm2022 from "../static/About/pm-22.svg";

import pm22top from "../static/About/pm22-top.png";

import pm22topM from "../static/About/pm22-top-m.png";


import Sec3mtopa from "../static/Home/storiesmt.png";
import Sec3mbtm from "../static/Home/storiesmc.svg";
import Sec3mtopb from "../static/Home/storiesmb.svg";

import blaine from "../static/About/blaine-cirle.png";

import progresstm from "../static/About/progress-t-m.svg";
import progressbm from "../static/About/progress-b-m.svg";

import winterArts1 from "../static/About/WinterArts2022-Chase-Gray-Pixel-Moments-Credit-SabrinaMisoCreative.jpg";
import vmf from "../static/About/VMF2021_VMF+Logo_Horizontal_Frame_REV_CMYK 2.png";
import winterArts3 from "../static/About/WinterArts2022-Chase-Gray-Pixel-Moments-Credit-SabrinaMisoCreative-1.jpg";


const About: React.FC = (): JSX.Element => {

	return (
		<React.Fragment>

			{/* <div className="about-header-offset"/> */}

			

			<section className="hero" id="about-pixel-moments">

				
				<div className="d-none d-lg-block back-sec-about-r">
					<img src={Sec1r} alt="London Drugs Vancouver" className=""/>
				</div>
				<div className="d-none d-lg-block back-sec-about-l">
					<img src={progressbm} alt="London Drugs Vancouver" className=""/>
				</div>

				<div className="d-block d-lg-none back-sec-m-1-1">
					<img src={progresstm} alt="London Drugs Vancouver" className=""/>
				</div>
				<div className="d-block d-lg-none back-sec-m-1-2">
					<img src={progressbm} alt="London Drugs Vancouver" className=""/>
				</div>

				<div className="hero__overlay hero-about">
				<Container className="component-about-hero  ">
					<h1 className="component-about-hero-heading p-3">About the <span> Pixel <br/> Moments Initiative</span></h1>
					
					<p className="component-about-hero-text py-3">Pixel Moments is an initiative of the St. Paul’s Foundation with the goal of bringing more awareness to mental health and raising funds for mental health resources at St. Paul’s Hospital. This year, we’re collaborating with the Vancouver Mural Festival to bring people together and celebrate local art. Donate as little as $5 to contribute a pixel for the mural.

					</p>
					<div className="component-about-hero-btn">
					<a href="https://secure3.convio.net/sphf/site/Donation2?df_id=2620&2620.donation=form1&mfc_pref=T" className="btn btn--stack " ><span>Donate Now</span></a>
					</div>
				</Container>
				</div>
			</section>
			
			
			<section className="pm2021">

				

				

				<Container className="">
				<Row>
					<Col xs={12} md={12}  className="pm-header py-3">
						<h2 className="text-center ">Pixel Moments 2021</h2>
						
						
						
					</Col>
					<Col xs={12} md={12}  className="py-3 text-center pm-header color-black">
						<p>In our inaugural year, Donations grew and pixels were added daily from January  to March 2021, until the final mural by artist Andrea Wan was revealed in April 2021.</p>
						
						
						
					</Col>
					<Col xs={12} md={12}  className="py-3 pm2021-g1">
					<div className="pm2021-g1-image ">
					
					<iframe width="736" height="480" src="https://www.youtube.com/embed/wyWFh58fb10" title="YouTube video player"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" ></iframe>
								</div>
								<div className="pm2021-g1-text">
									<h3>2021 Artist: Andrea Wan</h3>
									<p>A Hong Kong-born, Vancouver-raised visual artist and illustrator, Andrea creates otherworldly images and narratives propelled by her stream of consciousness. For Pixel Moments 2021, Andrea captured the struggle of being alone yet yearning to return to our community and loved ones.</p>
								</div>
								
						
						
					</Col>
					<Col xs={12} md={12}  className="py-3 pm2021-g2">
					<div className="pm2021-g2-text">
							<h3>AR Mural</h3>
							<p>Unlike a traditional mural, Pixel Moments engages with an additional digital experience. Stand on the marker, and with your smartphone, scan the mural to see augmented reality before your eyes. </p>
							
						</div>
					<div className="pm2021-g2-image ">
					<iframe width="736" height="480" src="https://www.youtube.com/embed/rcCu1sPfcWE" title="YouTube video player"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" ></iframe>
					
						</div>
						
						
						
						
					</Col>
				</Row>
			</Container>

			</section>


			<section className="pm-22-parent bg-white">

				
			<div className="d-none d-lg-block pm22-about-top">
				<img src={pm22top} alt="London Drugs Vancouver" className=""/>
				</div>
				

				<div className="d-block d-lg-none pm22-about-top-m">
				<img src={pm22topM} alt="London Drugs Vancouver" className=""/>
				</div>
				
				

				<Container className="pm-22">
				<Row>
					<Col xs={12} md={12}  className="py-3 pm-header about-custom-1 ">
						<h2 className="text-center ">Pixel Moments 2022: What’s New!</h2>
						
						
						
					</Col>
					<Col xs={12} md={12}  className="py-3 pm-22-image text-center">
					<img src={pm2022} alt="London Drugs Vancouver" className=""/>
						
						
						
					</Col>
					<Col xs={12} md={12}  className="py-3 pm-2022-text text-center">
						<p>This year, Pixel Moments is partnering with the Vancouver Mural Festival’s Winter Arts, an open-air gallery in locations around Vancouver that celebrates art and live experiences.</p>
						
						
						
						
					</Col>
					<Col xs={12} md={12}  className="py-3 text-center">
					<Link to="/artwork" className="btn btn--stack "><span>More about the art</span></Link>
					
						
						
					</Col>
				</Row>
			</Container>

			</section>

			<section className="pm-23-parent">

				<Container>

					<div className="about-winter-arts">
						<div className="about-winter-arts-1">
							<img src={winterArts1} alt="Winter arts Credits: Sabrina, Miso Creative" />
							<p>Credits: Sabrina, Miso Creative</p>
						</div>
						<div className="about-winter-arts-row">
							<div className="about-winter-arts-2">
								<p>Partnership with</p>
								<img src={vmf} alt="VMF" />
							</div>
							<div className="about-winter-arts-3">
								<p>Credits: Sabrina, Miso Creative</p>
								<img src={winterArts3} alt="Winter arts Credits: Sabrina, Miso Creative" />
							</div>
						</div>
					</div>

				</Container>


			</section>

			<section className="bg-white">


				
				

				<Container className="pm-22">
				<Row>
					
					
					<Col xs={12} md={12}  className="py-3 pm-2023-text ">
						<p>"With a pandemic that feels endless and concerns for the future after a summer of record heat and fire followed by a fall of record rain, many of us may find ourselves struggling with our mental wellbeing. All too often we dismiss these concerns as irrelevant or see them as weakness. But our mental health is as important as our physical health. Here at St. Paul's Mental Health Program we encourage people to monitor their own mental status and seek help without shame when they or their loved ones need it."
</p>
						
						
						
						
					</Col>
					<Col xs={12} md={12}  className="py-3 ">
						<Row>
							<Col xs={4} md={2}  className="py-3 user-photo">
							
							 <img src={blaine} alt="London Drugs Vancouver" className=""/>
							</Col>
							<Col xs={8} md={10}  className="py-3 user-desig">
							 <p>Blaine Bray, RPN, MA</p>
							<p>Program Director</p>
							<p>Acute & Tertiary Mental Health | Inner City Youth-Foundry Vancouver Granville</p>
							<p>Providence Health Care – St. Paul’s Hospital</p>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>

			</section>

			<section className="about-future-leaders">

			<div className="d-none d-lg-block fl-top">
				<img src={futureLtop} alt="London Drugs Vancouver" className=""/>
				</div>
				<div className="d-none d-lg-block fl-btm">
				<img src={futureLbtm} alt="London Drugs Vancouver" className=""/>
				</div>
				<div className="d-block d-lg-none back-sec-m-3-2">
							<img src={Sec3mtopa} alt="London Drugs Vancouver" className=""/>
						</div>
						<div className="d-block d-lg-none back-sec-m-3-1">
						<img src={Sec3mtopb} alt="London Drugs Vancouver" className=""/>
						</div>
						<div className="d-block d-lg-none back-sec-m-3-3">
							<img src={Sec3mbtm} alt="London Drugs Vancouver" className=""/>
						</div>
				

				<Container className="">
				<Row>
					<Col xs={12} md={12}  className="py-3 component-future-leader">
						<div className="compenent-future-leader-heading">
							<div className="participate-text- pm-header color-white">
								<h2 className="">Future Leaders</h2>
				
							</div>
						</div>
						<div className="component-future-leader-image ">
						<img src={futureLeaders} alt="Future Leaders"/>
						</div>
						<div className="component-future-leader-text">
						<p className="py-3">St. Paul’s Hospital Foundation Future Leaders is an engaged committee of young professionals who commit their time to raising awareness and funds for St. Paul’s Foundation. Since its inception in 2014, the committee has helped raise more than $1,100,000 for St. Paul’s Hospital.
<br/><br/>The Future Leaders encourage charitable giving at any age and any stage. Join the next generation of philanthropists whose goal is to change the future of health care at St. Paul’s Hospital.</p>

							<div>
							
							</div>

							
						</div>
						
						
					</Col>
				</Row>
			</Container>

			</section>


		</React.Fragment>
	);
}


export default About;
