/**
 * @brainhubeu/react-carousel
 * https://brainhubeu.github.io/react-carousel/docs/gettingStarted
 */

import * as React from "react";
import Carousel, {slidesToShowPlugin} from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import {useEffect, useState} from "react";
import {Container, Row, Col} from "react-bootstrap";
import storiesCarouselSlides, {IStoriesCarouselSlide} from "../data/storiesCarouselSlides";

const StoriesCarouselMobile: React.FC = (): JSX.Element => {
	const [slideIndex, setSlideIndex] = useState<number>(1); // Initialize index as 1 so we can "reset" it back to 0 after loading slides
	const [detailsStatus, setDetailsStatus] = useState<boolean>(false);
	const [slides, setSlides] = useState<IStoriesCarouselSlide[]>([]);

	useEffect(() => {
		setSlides(storiesCarouselSlides);
		setSlideIndex(0);
	}, []);

	function goToSlide(index: number): void {
		if (Number.isInteger(index) && index >= 0 && index < slides.length) {
			setSlideIndex(index);
		}
	}

	function goToPrevSlide(): void {
		if (slideIndex > 0) {
			goToSlide(slideIndex - 1);
		} else {
			goToSlide(slides.length - 1);
		}
	}

	function goToNextSlide(): void {
		if ((slideIndex + 1) < slides.length) {
			goToSlide(slideIndex + 1);
		} else {
			goToSlide(0);
		}
	}

	function carouselSlide(slide: IStoriesCarouselSlide, index: number): JSX.Element {
		return (
			<div key={`slide-${index}`} className="stories-parent-mobile">
				<Row>
					<Col md={12} className="stories-parent-mobile-image-parent">
						<div className="stories-parent-mobile-image-container">
						
							<iframe
								width="276"
								height="165"
								src={slide.video}
								frameBorder="0"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
								allowFullScreen
								title="Embedded youtube"
							/>
						</div>
					</Col>
				</Row>	
				<div className="stories-parent-mobile-image-parent">
					
						
					
				</div>
				
				<h3 className="stories-parent-mobile-title">{slide.title}</h3>
				<div className="stories-parent-mobile-text">{slide.description}</div>
				
			</div>
		)
	}


	function carouselDots(): JSX.Element[] {
		const rows: JSX.Element[] = [];
		slides.forEach((item: IStoriesCarouselSlide, index: number) => {
			// const quotient = Math.floor(slideIndex/slides.length);
			// const remainder = slideIndex  % slides.length;
			rows.push(
				<button
					key={`dot-${index}`}
					className={`stories-carousel-mobile__dots__item ${(slideIndex === index) ? "is-active" : ""}`}
					onClick={() => goToSlide(index)}
				/>
			)
		});
		return rows;
	}

	return (
		<React.Fragment>
			<Container className="mha-cont">
			<h2 className="	">Raising Awareness for Mental Health</h2>
				<p className="">
				Pixel Moments brings mental health to the forefront. Together, let’s raise more awareness, foster empathy, and encourage action. 
				</p>
			</Container>
			<div className={`stories-carousel-mobile  ${(detailsStatus) ? "is-details-active" : ""}`}>

				<div className="">
					<Carousel
						draggable={false}
						value={slideIndex}
						onChange={goToSlide}
						plugins={[
							"fastSwipe",
							// 'infinite',
							// 'arrows',
							// 'centered',
							
						]}
					>
						{slides.map(carouselSlide)}
					</Carousel>
				</div>
				{/* <div className="stories-carousel-mobile__arrows">
					<button
						onClick={goToPrevSlide}
						disabled={(slideIndex === 0)}
						className="stories-carousel-mobile__arrows__prev"
					>
						<i className="fal fa-angle-left"/>
					</button>
					<button
						onClick={goToNextSlide}
						disabled={(slideIndex + 1) === slides.length}
						className="stories-carousel-mobile__arrows__next"
					>
						<i className="fal fa-angle-right"/>
					</button>
				</div>
				<div className="stories-carousel-mobile__dots">
					{carouselDots()}
				</div> */}
			</div>
			
		</React.Fragment>
	);
}

export default StoriesCarouselMobile;
