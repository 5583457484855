import * as React from "react";
import {Col, Container, Row} from "react-bootstrap";


import heroImg from "../static/hero-art.jpg";

import Artist from "../static/Art/artist.png";
import Experience from "../static/Art/experience.png";
import CallToBreath from "../static/Art/call-to-breath.jpg";

import back1 from "../static/Art/back-1.svg";
import back2 from "../static/Art/back-2.png";
import back3 from "../static/Art/back-3.png";
import Map from "../static/Art/map.svg";

import pm22topM from "../static/About/pm22-top-m.png";
import pm22btmM	 from "../static/About/pm22-btm-m.png";



// import MyVideo from "../static/Art/video.mp4";
// import MyVideoM from "../static/Art/videom.mp4";

const Artwork: React.FC = (): JSX.Element => {


	return (
		<React.Fragment>

<section className="hero">
				<img src={heroImg} alt="London Drugs Vancouver" className="hero__img"/>
				{/*<video className="d-block d-lg-none" playsInline loop muted autoPlay poster={VRDemo}>*/}
				{/*	<source*/}
				{/*		src={MyVideoM}*/}
				{/*		type="video/mp4"*/}
				{/*	/>*/}
				{/*	*/}
				{/*</video>*/}
				{/*<video className="d-none d-lg-block" playsInline loop muted autoPlay poster={VRDemo}>*/}
				{/*	<source*/}
				{/*		src={MyVideo}*/}
				{/*		type="video/mp4"*/}
				{/*	/>*/}
				{/*</video> */}
				<div className="hero__overlay">
					<div>
						{/*<CountdownTimer/>*/}
						<div className="hero__overlay__tagline">
						<div className="hero__overlay__tagline-sub">
							Taking a Breath for Better Health
						</div>
						</div>
						
						<h4 className="py-5 text-white hero__overlay__text  override-work-sans">
							Healing and hope exist. Pixel Moments 2022 artwork reminds us that a simple inhale/exhale can start the journey, can bring us back to health.
						</h4>
						
						
							
						
						<div className="py-5 w-100">
							{/* hero__overlay__cta class is for Tag Manager tracking */}
							
							<a href="https://secure3.convio.net/sphf/site/Donation2?df_id=2620&2620.donation=form1&mfc_pref=T" className="btn btn--stack " ><span>Donate Now</span></a>
						</div>
						
					</div>
				</div>
			</section>

			<section className="pm2021">

				
			<div className="d-none d-lg-block art-back-1">
					<img src={back1} alt="London Drugs Vancouver" className=""/>
				</div>
				

				<Container className="">
				<Row>
					<Col xs={12} md={12}  className="py-3 pm-header art-header ">
						<h2 className="">The 2022 Mural</h2>
						
						
						
					</Col>
					
					<Col xs={12} md={12}  className="py-3 art2022-g1">
					<div className="art2022-g1-image ">
					<img src={Artist} alt="Chase Gray profile" className="image-art-grid"/>
								</div>
								<div className="art2022-g1-text">
									<h3>About the Artist: Chase Gray</h3>
									<p>Chase Gray is a trans-Indigiqueer or 2 Spirit xʷməθkʷəy̓əm (Musqueam) and Tsimshian artist. At 12 years old, he was introduced to mask-dancing and has danced along with the Git Hayetsk Dancers: "The People of the Copper Shield", who have greatly influenced his art. In 2021, Chase revived his lifelong dream—drawing, formline drawing in particular. He took inspiration from the Indigenous characters and stories of his childhood and blended them with mainstream concepts. Chase believes people from every culture can understand and appreciate Indigenous artwork in some way, and hopes his work captivates a wide audience.</p>
								</div>
								
						
						
					</Col>
					<Col xs={12} md={12}  className="py-3 art2022-g2">
					<div className="art2022-g2-image ">
					<img src={CallToBreath} alt="A Call to Breathe" className="image-art-grid"/>
								</div>
								<div className="art2022-g2-text">
									<h3>A Call to Breathe</h3>
									<p>Inhale and exhale, breathe, and find yourself in your own safe and happy place.</p>

									<p>"This wisdom is perhaps overshared, but breathwork does hold a lot of value in taking care of your mental health," says our 2022 mural artist.</p>

									<p>He describes the mural: "In "Breathe" the central figure is exhaling with the killer whales that surround it as the two side figures inhale," Chase begins. "They are surrounded by cultural images, protecting and guiding. Their minds are wrapped in orange, dwelling on the children they keep finding. Emotions about who they are and what they have gone through come like rivers. Taking moments to feel the inhale and exhale can help cope with those emotions. The sides of the artwork depict these emotions, but the bright and colourful remind us all of healing and resilience."</p>

								</div>



					</Col>
					<Col xs={12} md={12}  className="py-3 art2022-g1">
					<div className="art2022-g1-text">
							<h3>Our 2022 Mural Experience</h3>
							<p>Created by Chase Gray, our mural will bring donor selfies together into an interactive mosaic, with a virtual component that brings the artwork to life. The mural will first be unveiled during VMF Winter Arts (Feb 11-27). It will be located at the Winter Arts Hub, an all-ages, licensed open-air venue featuring art installations, a live performance stage, covered outdoor space with hot (and cold) drinks, food trucks and more! Located at šxʷƛ̓ənəq Xwtl'e7énḵ Square on the north side of the Vancouver Art Gallery. Then, in March 2022, the final larger than life mural will be installed above London Drugs at Granville & W. Georgia.</p>
							
						</div>
					<div className="art2022-g1-image ">
					<img src={Experience} alt="Andrea Wan profile" className="image-andrea-wan"/>
						</div>
						
						
						
						
					</Col>
					
				</Row>
			</Container>

			</section>
			<section className="artwork-about">

			<div className="d-none d-lg-block art-back-2">
					<img src={back2} alt="London Drugs Vancouver" className=""/>
				</div>
				
				<div className="d-none d-lg-block art-back-3">
					<img src={back3} alt="London Drugs Vancouver" className=""/>
				</div>
				<div className="d-block d-lg-none pm22-about-top-m">
				<img src={pm22topM} alt="London Drugs Vancouver" className=""/>
				</div>
				<div className="d-block d-lg-none pm22-about-btm-m">
				<img src={pm22btmM} alt="London Drugs Vancouver" className=""/>
				</div>
				
				

				<Container>

					<Row className=" pt-md-5">
						<Col xs={12} md={12} lg={5} className="mb-5 art-image">
							<img src={Map} alt="Andrea Wan profile" className="image-andrea-wan"/>
						</Col>
						<Col xs={12} md={12} lg={7} className="art-location">
							<div>
								<h3>Location of the Mural</h3>
							</div>	
							<div>
								<p>
									Pixel Moments' 2022 mosaic artwork is comprised of all our donor selfies from this year's campaign. A purely digital effort, anyone, anywhere could participate. Today, the art is in its physical location at Georgia & Granville, celebrating how our community came together to support mental health.
								</p>
							</div>
							
						</Col>
					</Row>
					
				</Container>
			</section>

		</React.Fragment>
	);
}


export default Artwork;
