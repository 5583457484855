import * as React from "react";
import {Container} from "react-bootstrap";

const PageNotFound: React.FC = (): JSX.Element => {

	return (
		<main className="basic-page">

			<section className="basic-page--hero"/>

			<section className="basic-page--content">

				<div className="absolute-pixel absolute-pixel--1">
					<div className="pixel pixel--1"/>
					<div className="pixel pixel--2"/>
					<div className="pixel pixel--3"/>
				</div>

				<Container>

					<h1 className="text-center mb-5">Page not found</h1>
					<p>
						We're sorry. The page you are looking for can not be found. If you believe this to be an error,
						please <a href="mailto:support@pixelmoments.org">contact us</a> and let us know about this
						issue.
					</p>
				</Container>
			</section>
		</main>
	);
}


export default PageNotFound;
