import * as React from "react";
import {Col, Container, Row} from "react-bootstrap";



import pmImg from "../static/Home/pm-img.jpg";





const PreviousMural: React.FC = (): JSX.Element => {

	return (
		<Container className="previous-mural-container">

			

			<Container className="steps-container-2-">
				<Row>
					<Col xs={12} md={12}  className="py-3 component-previous-mural">
						<div className="compenent-previous-mural-heading">
							<div className="color-white pm-header">
								<h2 className="">Your Donations to Date</h2>
				
							</div>
						</div>
						<div className="component-previous-mural-image ">
							<img src={pmImg} alt="img"/>
						</div>
						<div className="component-previous-mural-text">
							<p>This year marks our 2nd campaign raising funds and awareness for mental health issues. Over these past two years, our generous Pixel Moments donors have raised a total of $460,000 for mental health and substance abuse resources at St. Paul's Hospital. Thank you.</p>

							<p>Our 2022 mosaic mural contains 3,400 unique donor pixels and it's now on display above London Drugs at the corner of Granville & Georgia in downtown Vancouver.</p>

							<p>Swing by with your phone to try out the AR that makes the mural come alive!</p>

						</div>
						<div className="component-previous-mural-counter">
							<Row className="counter-row">
							
								<Col md={2}  sm={1} xs={1}>
									
								</Col>
								<Col md={4} sm={5} xs={5}>
									$460k <br/> <span>Raised</span>
								</Col>
								<Col md={4}  sm={5} xs={5}>
									3.4k <br/> <span>Unique Pixels</span>
								</Col>
								<Col md={2} sm={1} xs={1}>
									
								</Col>
								
							</Row>
						</div>
						
					</Col>
				</Row>
			</Container>
			
		</Container>
	);
}

PreviousMural.defaultProps = {};

export default PreviousMural;
