/**
 * @brainhubeu/react-carousel
 * https://brainhubeu.github.io/react-carousel/docs/gettingStarted
 */

import * as React from "react";
import '@brainhubeu/react-carousel/lib/style.css';
import {useEffect, useState} from "react";
import whatIsPixelMoments, {IWhatIsPixelMoments} from "../data/whatIsPixelMoments";

import Carousel from '@brainhubeu/react-carousel';
import {Col, Container, Row} from "react-bootstrap";


const WhatIsPixelMomentsMobile: React.FC = (): JSX.Element => {
	const [slideIndex, setSlideIndex] = useState<number>(1); // Initialize index as 1 so we can "reset" it back to 0 after loading slides
	const [detailsStatus, setDetailsStatus] = useState<boolean>(false);
	const [slides, setSlides] = useState<IWhatIsPixelMoments[]>([]);

	useEffect(() => {
		setSlides(whatIsPixelMoments);
		setSlideIndex(0);
	}, []);

	function goToSlide(index: number): void {
		if (Number.isInteger(index) && index >= 0 && index < slides.length) {
			setSlideIndex(index);
		}
	}

	function goToPrevSlide(): void {
		if (slideIndex > 0) {
			goToSlide(slideIndex - 1);
		} else {
			goToSlide(slides.length - 1);
		}
	}

	function goToNextSlide(): void {
		if ((slideIndex + 1) < slides.length) {
			goToSlide(slideIndex + 1);
		} else {
			goToSlide(0);
		}
	}

	function carouselSlide(slide: IWhatIsPixelMoments, index: number): JSX.Element {
		return (
			<div key={`slide-${index}`} className="whatif-carousel-mobile__slide">
				<Row>
					<Col md={12} className="carcousel-img-parent">
					<img className={'stories-carousel-mobile__slide__image '  +slide.clname}  src={slide.image} alt={slide.title}/>
					</Col>
					<Col md={12} className="what-if-title">
					{slide.title}
					</Col>
					<Col md={12} className="what-if-desc">
					{slide.description}
					</Col>
				</Row>
			</div>
		)
	}


	function carouselDots(): JSX.Element[] {
		const rows: JSX.Element[] = [];
		slides.forEach((item: IWhatIsPixelMoments, index: number) => {
			// const quotient = Math.floor(slideIndex/slides.length);
			// const remainder = slideIndex  % slides.length;
			rows.push(
				<button
					key={`dot-${index}`}
					className={`stories-carousel-mobile__dots__item ${(slideIndex === index) ? "is-active" : ""}`}
					onClick={() => goToSlide(index)}
				/>
			)
		});
		return rows;
	}

	return (
		<React.Fragment>
			<Container  className={(detailsStatus) ? "d-none" : ""}>
			<div className="homepage-story-card"><h2 className="homepage-whatif-card--header">What is Pixel Moments?</h2></div>
			</Container>
			<div className={`whatif-carousel-mobile  ${(detailsStatus) ? "is-details-active" : ""}`}>

				<div className="whatif-carousel-mobile-body">
				{slides.map(carouselSlide)}
					{/* <Carousel
						draggable={true}
						value={slideIndex}
						onChange={goToSlide}
						plugins={[
							"fastSwipe",
							// 'infinite',
							// 'arrows',
							// 'centered',
							
						]}
					>
						
					</Carousel> */}
				</div>
				{/* <div className="stories-carousel-mobile__arrows">
					<button
						onClick={goToPrevSlide}
						disabled={(slideIndex === 0)}
						className="stories-carousel-mobile__arrows__prev"
					>
						<i className="fal fa-angle-left"/>
					</button>
					<button
						onClick={goToNextSlide}
						disabled={(slideIndex + 1) === slides.length}
						className="stories-carousel-mobile__arrows__next"
					>
						<i className="fal fa-angle-right"/>
					</button>
				</div>
				<div className="whatif-carousel-mobile__dots">
					{carouselDots()}
				</div> */}
			</div>
		</React.Fragment>
	);
}

export default WhatIsPixelMomentsMobile;
