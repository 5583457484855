import * as React from "react";
import novus from "../static/sponsors/novus.png";
import szocs from "../static/sponsors/SZOCS-FOUNDATION-logo-1.png";
import bakerWest from "../static/sponsors/Bronze-Sponsors/logo-baker-west.png";

import {Container} from "react-bootstrap";

import vs1 from "../static/sponsors/Venue-Sponsor/london-drugs.png"

import gs1 from "../static/sponsors/Gold-Sponsors/sanstrom.png"
import b1 from "../static/sponsors/Bronze-Sponsors/b1.png"
import b2 from "../static/sponsors/Bronze-Sponsors/b2.png"

import iconCo from "../static/sponsors/Founder_s-Circle/logo-icon-co.svg"
import fc2 from "../static/sponsors/Founder_s-Circle/fc-2.png"
import fc3 from "../static/sponsors/Founder_s-Circle/fc-3.png"

import tt1 from "../static/sponsors/Thanks-to/tt1.png"
import tt2 from "../static/sponsors/Thanks-to/tt2.png"
import tt3 from "../static/sponsors/Thanks-to/tt3.png"
import tt4 from "../static/sponsors/Thanks-to/logo-mx-movies.jpg"
import tt5 from "../static/sponsors/Thanks-to/logo-aberdeen-centre.png"

import zlc from "../static/sponsors/Supporters/zlc-financial-black.png"
import jackChang from "../static/sponsors/Supporters/DR.JC-black.svg"
import shirleyWong from "../static/sponsors/Supporters/DR.SMW-black.svg"

import cp1 from "../static/sponsors/Community-Partners/cp1.png"
import blastMedia from "../static/sponsors/Community-Partners/logo-blast-media.svg"
import concordPacific from "../static/sponsors/Concord-Pacific.png"
import houseClub from "../static/sponsors/Bronze-Sponsors/logo-the-house-club.png"
import claridge from "../static/sponsors/Bronze-Sponsors/logo-Claridge_Pink.svg"

import pr1 from "../static/sponsors/Public-Relations/logo_small_pantone.png"


import back1 from "../static/sponsors/spons-back.svg";


import cp2 from "../static/Home/what-if-2.png";

interface sponsor {
	image?: string;
	alt: string;
	link?: string;
}

interface sponsorGroupItem {
	header: string;
	classList?: string[];
	sponsorArray: sponsor[];
}

const presentingList: sponsor[] = [
	//  {
	//  	image: vs1,
	//  	alt: "Sandstorm Gold Royalties logo",
	//  	link: "https://www.sandstormgold.com/our-company/company-overview/",
	// },
];

const publicRelationsList: sponsor[] = [
	{
		image: pr1,
		alt: "SCPR",
		link: "https://www.sophiachengpr.com/",
   },
];

const venueList: sponsor[] = [
	{
		image: vs1,
		alt: "London Drugs",
		link: "https://www.londondrugs.com/",
	},
];

const goldList: sponsor[] = [
	{
		image: gs1,
		alt: "Sandstorm Gold Royalties",
		link: "https://www.sandstormgold.com/our-company/company-overview/",
	},
];

const silverList: sponsor[] = [
	{
		image: szocs,
		alt: "SZOCS Foundation",
	},
];

const bronzeList: sponsor[] = [
	{
		image: b1,
		alt: "Capilano Audi",
		link: "https://www.capilanoaudi.com/",
	},
	{
		image: b2,
		alt: "Strand",
		link: "https://stranddev.com/en/",
	},
	{
		image: bakerWest,
		alt: "Baker West",
		link: "https://bakerwest.com/",
	},
	{
		image: novus,
		alt: "Novus Entertainment",
		link: "https://www.novusnow.ca/",
	},
	{
		image: concordPacific,
		alt: "Concord Pacific",
		link: "https://www.concordpacific.com/",
	},
	{
		image: houseClub,
		alt: "The House Club",
		link: "https://thehouseclub.com/",
	},
	{
		image: claridge,
		alt: "Claridge Real Estate Advisors",
		link: "https://claridgeadvisors.com/",
	},

];

const supportersList: sponsor[] = [
	
	{
		image: zlc,
		alt: "ZLC Financial",
		link: "https://www.zlc.net/",
	},
	{
		image: jackChang,
		alt: "Dr. Jack Chang",
		// link: "https://www.zlc.net/",
	},
	{
		image: shirleyWong,
		alt: "Dr. Shirley M. Wong Foundation",
		// link: "https://www.zlc.net/",
	},

];

const founderList: sponsor[] = [
	{
		image: fc2,
		alt: "Prizm Media Inc.",
		link: "https://www.prizmmedia.com/about-us/",
	},
	{
		image: iconCo,
		alt: "icon&co.",
		link: "https://iconco.ca/about_us/",
	},
	{
		image: fc3,
		alt: "LNG Studios",
		link: "http://www.lngstudios.com/about",
	},
];

const thanksList: sponsor[] = [
	
	{
		image: tt1,
		alt: "FMA Entertainment",
		link: "https://www.fmaentertainment.com/",
	},
	{
		image: tt2,
		alt: "Kaydence",
		link: "https://www.kaydence.ca/",
	},
	{
		image: tt3,
		alt: "Mythical City",
		link: "https://www.mythicalcitygames.com/",
	},
	{
		image: tt4,
		alt: "MX Movies",
		link: "https://www.mxmovies.com/",
	},
	{
		image: tt5,
		alt: "Aberdeen Centre",
		link: "https://www.aberdeencentre.com/",
	},

];


const communityPartnerList: sponsor[] = [
	{
		image: cp1,
		alt: "Hawkers",
		link: "https://hawkerbox.com/",
	},
	{
		image: cp2,
		alt: "VMF Winter Arts",
		link: "https://www.winterartsfest.com/",
	},
	{
		image: blastMedia,
		alt: "Blast Media",
		link: "https://www.blastmediainc.com/",
	},
];






// const publicRelationsList: sponsor[] = [
// 	{
// 		image: frontAndCentre,
// 		alt: "Front + Centre logo",
// 		link: "https://www.frontandcentre.co/about-us-1",
// 	},
// 	{
// 		image: jive,
// 		alt: "Jive PR + Digital logo",
// 		link: "https://jiveprdigital.com/agency/",
// 	},
// ];

const sponsorGroupArray: sponsorGroupItem[] = [
	
	{
		header: "Venue Sponsor",
		classList: ["venue-sponsor"],
		sponsorArray: venueList,
	},
	{
		header: "Gold Sponsor",
		classList: ["gold-sponsor"],
		sponsorArray: goldList,
	},
	{
		header: "Silver Sponsor",
		classList: ["silver-sponsor"],
		sponsorArray: silverList,
	},
	{
		header: "Bronze Sponsor",
		classList: ["bronze-sponsor"],
		sponsorArray: bronzeList,
	},
	{
		header: "Supporters",
		classList: ["supporters"],
		sponsorArray: supportersList,
	},
	{
		header: "Founder's Circle",
		sponsorArray: founderList,
	},
	{
		header: "Thanks to",
		sponsorArray: thanksList,
	},
	{
		header: "Community Partner",
		sponsorArray: communityPartnerList,
	},
	{
		header: "PR Sponsor",
		classList: ["presenting-sponsor-"],
		sponsorArray: publicRelationsList,
	}
	// {
	// 	header: "Sponsors",
	// 	sponsorArray: mainList,
	// },
	// {
	// 	header: "Public Relations",
	// 	sponsorArray: publicRelationsList,
	// },
];

let sponsorCatCount = 1;
let sponsorColCount = 0;

let inc =1;

function renderSponsorGroup(groupItem: sponsorGroupItem, itemNumber: number): JSX.Element {
	sponsorColCount = sponsorCatCount;
	sponsorCatCount = (sponsorCatCount + 1) ;



	return (
		<div key={`${groupItem.header}-${itemNumber}`}
		     className={`py-5 pb-0  ${(groupItem.classList) && groupItem.classList.join(" ")}`}>
			<div className="sponsors-wrapper">
				<h6 className="py-3 pt-md-5 font-weight-bold">{groupItem.header}</h6>

				<div className="sponsor-strip">
				{(groupItem.sponsorArray).map((item, index) => renderSponsorList(item, index))}
				</div>
			</div>
		</div>

	);
}

function renderSponsorList(item: sponsor, index: number): JSX.Element {

	const logo: JSX.Element = (item.image) ?
		(<img src={item.image} alt={item.alt}/>) :
		(<div className="logo-text"></div>);
	let content: JSX.Element = logo;
	if (item.link) {
		content = <a href={item.link} target="_blank" rel="noreferrer noopener">
			{logo}
		</a>;
	}

	let num = sponsorColCount;

	let cName = 'color-rand-'+num


	if(sponsorColCount === 10){
		sponsorColCount = 1;
	}
	else{
		sponsorColCount = (sponsorColCount +1);
	}




	return (
		<div  key={`sponsor-${index}`} className="sponsors-block-parent ">
			<a href={item.link}>
				
			
			<div  className={"sponsors-block color-rand-" + ((num+1)%10) }>


			<div className="company-image">
			<a href={item.link}>
				{(item.image) && (<img className="img-fluid " src={item.image} alt={item.alt}/>)}
				</a>

			</div>
			<div className="company-name">


			{item.alt}
			</div>

		</div>
		</a>
		</div>

	);

}

const Sponsors: React.FC = (): JSX.Element => {

	return (
		<React.Fragment>

<section className="hero" id="sponsor-pixel-moments">


				<div className="hero__overlay hero-sponsorPage">
				<div className="d-none d-lg-block sponsor-back-1">
					<img src={back1} alt="London Drugs Vancouver" className=""/>
				</div>
				<Container className="component-sponsor-hero  ">
					<h2 className="component-sponsor-hero-heading p-3">Thank You to Our Community Sponsors</h2>

					<p className="component-sponsor-hero-text py-3">Our sponsors are leaders in the community that play a vital role in the success of raising awareness for mental health in our community. The funds they generously provide will directly support mental health programs and services at St. Paul’s Hospital.
					</p>
					<div className="component-sponsor-hero-btn">
					<a
								href={"mailto:support@pixelmoments.org"}
								target="_blank"
								rel="noopener noreferrer"
								className="btn btn--stack "
							>
								<span>Become a Sponsor</span>
							</a>
					</div>
				</Container>
				</div>
			</section>

			<section className="sponsor-list">

				<Container className="container-list">
				{sponsorGroupArray.map((item, index) => renderSponsorGroup(item, index))}
					</Container>



			</section>

		</React.Fragment>
	);
}


export default Sponsors;
