import * as React from "react";
import {Container} from "react-bootstrap";
import {useEffect} from "react";
import iFrameResize from "../utils/iFrameResize";

const Donate: React.FC = (): JSX.Element => {
	useEffect(() => {
		// @ts-ignore
		iFrameResize('#pixelMomentDF');

	}, []);
	return (
		<React.Fragment>

			<section className="donate-hero">
				<Container className="d-block d-md-none">
					<strong>Thank you for supporting Pixel Moments</strong>
				</Container>
			</section>
			<div className="pt-5">
				<div className="d-none d-md-block">
					<h2 className="donate-desktop-title">Thank you for supporting Pixel Moments</h2>
				</div>
				<iframe
					id="pixelMomentDF"
					src="https://secure3.convio.net/sphf/site/Donation2?df_id=2560&mfc_pref=T&2560.donation=form1"
					scrolling="no"
					sandbox="allow-top-navigation allow-scripts allow-forms allow-popups allow-popups-to-escape-sandbox"
					title="St. Pauls Donation"
				/>
			</div>

		</React.Fragment>
	);
}

Donate.defaultProps = {};

export default Donate;
