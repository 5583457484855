import * as React from "react";
import {Container} from "react-bootstrap";
import PrivacyPolicyContent from "../components/PrivacyPolicyContent";

const PrivacyPolicy: React.FC = (): JSX.Element => {

	return (
		<main className="basic-page bg-white">

			

			<section className="basic-page--content">

				

				<Container>
					<PrivacyPolicyContent/>
				</Container>
			</section>
		</main>
	);
}


export default PrivacyPolicy;
