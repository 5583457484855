import * as React from "react";
import {ChangeEvent, Dispatch, RefObject, SetStateAction, SyntheticEvent, useEffect, useState} from "react";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {useInput} from "./input/UseInput";
import backendAdapters, {IStoriesFormData} from "backend-adapters";
import ImageCrop from "./ImageCrop";
import {getImageDimensions} from "../utils/getImageDimensions";
import {EModalContent} from "../pages/Stories";
import {Modal} from "react-bootstrap";
import GrpImg from "../static/foo-b.svg";
import {Row, Col} from "react-bootstrap"
interface IStoriesFormProps extends RouteComponentProps<{ id?: string }> {
	donationStep: number;
	updateDonationStep: Dispatch<SetStateAction<number>>;
	setModalContent: Dispatch<SetStateAction<EModalContent>>;
}

function validateEmail(v: string): boolean {
	// Note: removed 2 unnecessary escape characters; kept original line commented in case something breaks and we need it back
	// const regex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	const regex: RegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return v !== undefined && regex.test(v.toLowerCase());
}

const StoriesForm: React.FC<IStoriesFormProps> = (props: IStoriesFormProps): JSX.Element => {

	const {donationStep, updateDonationStep, setModalContent, history, match} = props;
	const [showProcessingModal, setShowProcessingModal] = useState<boolean>(false);
	const [showAddAnotherModal, setShowAddAnotherModal] = useState<boolean>(false);
	const [entryId, setEntryId] = useState<string>("");
	const {value: firstName, setValue: setFirstName, bind: bindFirstName} = useInput("");
	const {value: lastName, setValue: setLastName, bind: bindLastName} = useInput("");
	const {value: email, setValue: setEmail, bind: bindEmail} = useInput("");
	const {value: instaHandle, setValue: setInstaHandle, bind: bindInstaHandle} = useInput("");
	const {value: story, setValue: setStory, bind: bindStory} = useInput("");

	const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});
	const [tos, changeTos] = useState<string>("");
	const [fileImage, setFileImage] = useState<File>();
	const [cropperKey, setCropperKey] = useState<number>(0);
	const [fileChanges, setFileChanges] = useState<number>(0);
	const [finalImageDimensions, setFinalImageDimensions] = useState<{ width: number; height: number; } | false>(false);
	const [cropBoxData, setCropBoxData] = useState<Cropper.CropBoxData & { containerWidth: number; }>();
	const [finalImageBase64, setFinalImageBase64] = useState<string>("");
	const [validateTrigger, updateValidateTrigger] = useState(false);
	const fileInput: RefObject<HTMLInputElement> = React.createRef();

	useEffect(() => {
		(async () => {
			if (match.params.id) {
				const result = await backendAdapters.nodeBackendAdapter.getStoryById(match.params.id);
				setFirstName(result.data?.firstName as string);
				setLastName(result.data?.lastName as string);
				setEmail(result.data?.email as string);
				setInstaHandle(result.data?.instaHandle as string)
				setStory(result.data?.story as string)
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [match]);

	useEffect(() => {
		if (validateTrigger) {
			validateForm();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [firstName, lastName, email,instaHandle, story, fileImage, finalImageDimensions, validateTrigger]);

	useEffect(() => {
		(async () => {
			if (finalImageBase64) {
				setFinalImageDimensions(await getImageDimensions(finalImageBase64));
			}
		})();
	}, [finalImageBase64, fileChanges]);

	function validateForm(): boolean {

		console.log("yao")

		updateValidateTrigger(true);

		const errors: { [key: string]: string } = {};
		let validated: boolean = true;

		if (!firstName) {
			errors.firstName = "First Name is required";
			validated = false;
		}

		if (!lastName) {
			errors.lastName = "Last Name is required";
			validated = false;
		}

		// if (!instaHandle) {
		// 	errors.instaHandle = "Insta Handle is required";
		// 	validated = false;
		// }

		if (!email) {
			errors.email = "Email is required";
			validated = false;
			console.log("yo")
		} else if (!validateEmail(email)) {
			errors.email = "Please enter a valid email";
			validated = false;
			console.log("yo1")
		}

		if (!finalImageBase64) {
			errors.photo = "A PNG or JPG file is required";
			validated = false;
		} else if (!finalImageDimensions) {
			errors.photo = "Processing image. If this message does not automatically go away after a few seconds, image may be too large. Please shrink the size of the crop box.";
			validated = false;
		} else if (finalImageDimensions.height < 450 || finalImageDimensions.width < 450) {
			errors.photo = "Minimum size for cropped image is 450px * 450px";
			validated = false;

		}

		setFormErrors(errors);
		return validated;
	}

	function validateConfirm(): boolean {
		updateValidateTrigger(true);
		const errors: { [key: string]: string } = {};
		let validated: boolean = true;

		if (!firstName) {
			errors.firstName = "First Name is required";
			validated = false;
		}

		if (!lastName) {
			errors.lastName = "Last Name is required";
			validated = false;
		}

		// if (!instaHandle) {
		// 	errors.instaHandle = "Insta Handle is required";
		// 	validated = false;
		// }

		if (!email) {
			errors.email = "Email is required";
			validated = false;
		} else if (!validateEmail(email)) {
			errors.email = "Please enter a valid email";
			validated = false;
		}

		console.log(finalImageBase64)
		if (!finalImageBase64) {
			errors.photo = "A PNG or JPG file is required";
			validated = false;
		} else if (!finalImageDimensions) {
			errors.photo = "Processing image. If this message does not automatically go away after a few seconds, image may be too large. Please shrink the size of the crop box.";
			validated = false;
		} else if (finalImageDimensions.height < 450 || finalImageDimensions.width < 450) {
			errors.photo = "Minimum size for cropped image is 450px * 450px";
			validated = false;

		}

		if (!tos) {
			errors.tos = "You must agree to the terms and conditions";
			validated = false;
		}

		setFormErrors(errors);
		return validated;
	}

	function handleFiles(e: ChangeEvent<HTMLInputElement>) {
		const fileList = e.target.files;
		(async () => {
			if (fileList && fileList[0]) {
				const imageDimensions = await getImageDimensions(URL.createObjectURL(fileList[0]));
				// console.log("image dimensions", imageDimensions);
				if (imageDimensions.height >= 450 || imageDimensions.width >= 450) {
					setFileImage(fileList[0]);
					setCropperKey(cropperKey + 1);
				} else {
					setFormErrors({...formErrors, photo: "Minimum size for uploaded image is 450px * 450px"});
				}
			}
		})();

	}

	function handleUploadButton() {
		if (fileInput && fileInput.current) {
			fileInput.current.click();
		}
	}

	async function saveFinalBase64(base64: string) {
		setFinalImageDimensions(false);
		setFormErrors({...formErrors, photo: ""});
		setFinalImageBase64(base64);
		setFileChanges(fileChanges + 1);
	}

	function handleSubmit(evt: SyntheticEvent) {
		evt.preventDefault();
		console.log("submit")
		if (validateConfirm()) {
			(async () => {
				setShowProcessingModal(true);
				const values: IStoriesFormData = {
					firstName,
					lastName,
					instaHandle,
					email,
					story,
					tos,
					previousId: match.params.id,
					imageData: finalImageBase64,
				}
				const result = await backendAdapters.nodeBackendAdapter.submitStoriesForm(values);
				setShowProcessingModal(false);
				if (result.success) {
					setEntryId(result.data?._id);
					setShowAddAnotherModal(true);
				} else {
					setModalContent(EModalContent.ERROR);
				}

			})();
		}
	}

	function renderImage(): JSX.Element {
		if (fileImage) {
			if (donationStep === 2) { // Confirm page
				return (
					<div className="donate-component__field--image__preview">
						<img src={finalImageBase64} alt="preview"/>
					</div>
				);
			} else {
				return (
						<ImageCrop
							
							image={fileImage}
							submitBase64={saveFinalBase64}
							setCropBoxData={setCropBoxData}
							initialCropBoxData={cropBoxData}
							key={cropperKey}
						/>
					
				);
			}
		} else {
			return <React.Fragment/>;
		}
	}

	function goToConfirm() {
		if (validateForm()) {
			//updateDonationStep(2);
		}
	}

	function goToEdit() {
		updateDonationStep(1);
	}

	function goToThankYou() {
		setShowAddAnotherModal(false);
		history.push(`/pixel-upload/thank-you/${firstName}`);
	}

	function goToAddAnotherPixel() {
		setShowAddAnotherModal(false);
		history.push(`/pixel-upload/add/${entryId}`);
		// Need to force a refresh since the new route uses the same component
		window.location.reload();
	}

	function renderButtons(): JSX.Element {
		switch (donationStep) {
			case 0: // Form page
				return (
					// <button
					// 	type="button"
					// 	onClick={goToConfirm}
					// 	className="btn btn--blue-outline btn--floatleft"
					// >
					// 	Next
					// </button>
					<button
							type="submit"
							onClick={goToConfirm}
							className="btn  btn--blue-outline btn--floatleft"
						>
							Confirm and Share
					</button>
				);
			case 1: // Edit page
				return (
					<button
						type="button"
						onClick={goToConfirm}
						className="btn btn--floatleft btn--blue-outline"
					>
						Confirm
					</button>
				);
			case 2: // Confirm page
			default:
				return (
					<React.Fragment>
						<button
							type="button"
							onClick={goToEdit}
							className="btn btn--bg-dark btn--edit"
						>
							Edit
						</button>
						<button
							type="submit"
							className="btn  btn--blue-outline btn--floatleft"
						>
							Confirm
						</button>
					</React.Fragment>
				);
		}
	}


	return (
		<React.Fragment>
			<Modal
				show={showAddAnotherModal}
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Body className="add-another-modal text-center pt-5">
					<h2>Pixel Submitted</h2>
					<p>Would you like to upload another pixel?</p>
				</Modal.Body>
				<Modal.Footer className="justify-content-between">
					<button
						onClick={goToThankYou}
						className="btn btn--bg-dark"
					>
						No
					</button>
					<button
						onClick={goToAddAnotherPixel}
						className="btn btn--bg-accent"
					>
						Yes
					</button>
				</Modal.Footer>
			</Modal>
			<Modal
				show={showProcessingModal}
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Body className="processing-modal">
					<h2>Processing submission. Please wait.</h2>
					<div>
						<i className="fas fa-spinner fa-spin"/>
					</div>
				</Modal.Body>
			</Modal>
			
			<form onSubmit={handleSubmit} className={`donate-component step-${donationStep}`}>

				<div className="donate-component__desktop-content donate-component__desktop-content--initial">
				<div className="component-thank-you--header">
					<h2 >Thank You!</h2>
					<img src={GrpImg}  alt="grp-img" />
				</div>
				<div  className="component-thank-you--description-"><p className="upload-des">Your donation has an impact on every patient, resident, family member in Mental Health department  of St Paul’s Hospital.</p></div>
					
				</div>

					

				<div className="donate-component__desktop-content donate-component__desktop-content--confirm">
					<h2>Confirm Your Information</h2>
					<button onClick={goToEdit}>EDIT</button>
					<p>Thank you for uploading your photo, {firstName}</p>
				</div>

				<div className="donate-component__desktop-content donate-component__desktop-content--edit">
					<h2>Edit Your Information</h2>
					<p>Thank you for uploading your photo, {firstName}</p>
				</div>

				<h5 className="donate-component__step-title">Donor Information 
				</h5>

				<Row>
					<Col md={4} sm={12} xs={12}>
						<div className="donate-component__field donate-component__field--first-name">
							<label className="w-100">
								<span>First Name:</span>
								<input
								className={formErrors.firstName && 'form-error-ip'}
									type="text"
									placeholder="Enter your first name"
									disabled={(donationStep === 2)}
									onKeyPress={(e) => {
										e.key === 'Enter' && e.preventDefault();
									}}
									{...bindFirstName}
								/>
							</label>
							{formErrors.firstName && (<div className="form-error">{formErrors.firstName}</div>)}
						</div>
					</Col>
					<Col md={4} sm={12} xs={12}>
						<div className="donate-component__field donate-component__field--last-name">
							<label className="w-100">
								<span>Last Name:</span>
								<input
								className={formErrors.lastName && 'form-error-ip'}
									type="text"
									placeholder="Enter your last name"
									disabled={(donationStep === 2)}
									onKeyPress={(e) => {
										e.key === 'Enter' && e.preventDefault();
									}}
									{...bindLastName}
								/>
							</label>
							{formErrors.lastName && (<div className="form-error">{formErrors.lastName}</div>)}
						</div>
					</Col>
					<Col md={4} sm={12} xs={12}>
						<div className="donate-component__field donate-component__field--last-name">
							<label className="w-100">
								<span>Instagram (Optional):</span>
								<input
								
									type="text"
									placeholder="@"
									disabled={(donationStep === 2)}
									onKeyPress={(e) => {
										e.key === 'Enter' && e.preventDefault();
									}}
									{...bindInstaHandle}
								/>
							</label>
							
						</div>
					</Col>
					<Col>
					<div className="donate-component__field donate-component__field--email">
						<label className="w-100">
							<span>Email:</span>
							<input
								className={formErrors.email && 'form-error-ip'}
								type="email"
								placeholder="Enter your email"
								disabled={(donationStep === 2)}
								onKeyPress={(e) => {
									e.key === 'Enter' && e.preventDefault();
								}}
								{...bindEmail}
							/>
						</label>
						{formErrors.email && (<div className="form-error">{formErrors.email}</div>)}
					</div>
					</Col>
				</Row>

				

				

				

				

				<hr/>

				<h5 className="donate-component__step-title">Upload your Pixel </h5>

				<p className="donate-component__description">Upload a selfie that will become a pixel in our final artwork! The minimum resolution of each pixel is 450px * 450px. The maximum file we can accept is 5MB. If your file is too large or too small we may not be able to use it.</p>
				<div>
				<p className="reminder-terms-condition">
							Read the <span onClick={() => setModalContent(EModalContent.TERMS_CONDITIONS)}>Terms and Conditions.</span>
						</p>
				</div>
				<div
					className={` ${(fileImage) && "is-file-uploaded"}`}
				>
					<Row className="upload-btn">
						
						{renderImage()}
						
						<Col md={8}>
						<div className="donate-component__field--image__input">
						<button
							type="button"
							onClick={handleUploadButton}
							className="btn btn--blue-outline"
						>
							{(fileImage) ? "Select Another Photo" : "Upload"}
						</button>
						{(finalImageDimensions) && (
							<p className="size-details">Image
								size: {finalImageDimensions.width} * {finalImageDimensions.height}</p>
						)}
						<input type="file" name="photo" ref={fileInput} onChange={handleFiles} accept="image/*"/>
						{formErrors.photo && (<div className="form-error">{formErrors.photo}</div>)}
						
					</div>
						</Col>
					</Row>
					
					
				</div>
				<hr/>
				<h5 className="donate-component__step-title">Share Your Story (Optional) </h5>

				<p className="donate-component__description">Share a story, a moment, or a positive message with us. It can be about anything related to mental health and wellness.</p>
				
				<div className="donate-component__field donate-component__field--">
					<label className="w-100">
						<span>Your Story:</span>
						<textarea
						
							
									
							
							onKeyPress={(e) => {
								e.key === 'Enter' && e.preventDefault();
							}}
							{...bindStory}
						/>
					</label>
					
				</div>
				
				<hr/>

				<div className="donate-component__field donate-component__field--tos">
					<input
						className="mr-3"
						type="checkbox"
						id="tos"
						name="Terms of Service"
						value={"I agree to the terms and agreement"}
						checked={!!(tos)}
						onChange={() => {
							if (tos) {
								changeTos("");
							} else {
								changeTos("I confirm I'm over 19 years of age and agree to publish my image as per the Terms and Conditions");
							}
						}}
					/>
					<label htmlFor="tos">
						I agree to the <span
						className="reminder-terms-condition" onClick={() => setModalContent(EModalContent.TERMS_CONDITIONS)}> <span> Terms and Conditions</span></span>.
					</label>
					{formErrors.tos && (<div className="form-error">{formErrors.tos}</div>)}
				</div>
				<div className="donate-component__reminder">
					Please review to ensure your photo and information are accurate. You can go back and change your
					photo and information.
				</div>
				<div className="donate-component__submit-buttons">
					{renderButtons()}
				</div>
			</form>
		</React.Fragment>

	);
}

StoriesForm.defaultProps = {};

export default withRouter(StoriesForm);
