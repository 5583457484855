import * as React from "react";
import {Button, Container, Modal} from "react-bootstrap";
import StoriesForm from "../components/StoriesForm";
import {useState} from "react";
import TermsAndConditionsContent from "../components/TermsAndConditionsContent";
import PrivacyPolicyContent from "../components/PrivacyPolicyContent";

// enum ETabs {
// 	DONATE,
// 	FAQ
// }

export enum EModalContent {
	NONE,
	TERMS_CONDITIONS,
	PRIVACY_POLICY,
	ERROR,
}

const Stories: React.FC = (): JSX.Element => {

	//const [currentTab, updateCurrentTab] = useState<ETabs>(ETabs.DONATE);
	const [donationStep, updateDonationStep] = useState<number>(0);
	const [modalContent, setModalContent] = useState<EModalContent>(EModalContent.NONE);

	function storiesModal(): JSX.Element {
		let title: string = "";
		let content: JSX.Element = (<React.Fragment/>);
		switch (modalContent) {
			case EModalContent.ERROR:
				title = "Error";
				content = (
					<p>
						Story unable to be submitted. Please try again later.
					</p>
				)
				break;
			case EModalContent.TERMS_CONDITIONS:
				title = "Terms and Conditions";
				content = (
					<TermsAndConditionsContent/>
				)
				break;
			case EModalContent.PRIVACY_POLICY:
				title = "Privacy Policy";
				content = (
					<PrivacyPolicyContent/>
				)
				break;
		}
		return (
			<Modal
				show={(modalContent !== EModalContent.NONE)}
				onHide={() => setModalContent(EModalContent.NONE)}
				size="xl"
			>
				<Modal.Header closeButton>
					<Modal.Title>
						{title}
					</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					{content}
				</Modal.Body>
				<Modal.Footer>
					<Button variant="danger" onClick={() => setModalContent(EModalContent.NONE)}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}

	return (
		<React.Fragment>
			{storiesModal()}
			<section className="thanku--content bg-white">

				

				<Container>
					

				<StoriesForm donationStep={donationStep} updateDonationStep={updateDonationStep}
								             setModalContent={setModalContent}/>

					

					
				</Container>
			</section>
			{/* <section className="donate-hero">
				<Container className="d-block d-md-none">
					{(donationStep === 0) ?
						(<span>Thank you! St. Paul's Foundation has successfully received your donation. You will be receiving a confirmation email with tax receipt shortly.</span>) :
						(<strong>Thank you for supporting Pixel Moments</strong>)}
				</Container>
			</section>
			<div className="donate-bg">
				
				<Container fluid={true}>

					<section className="donate-tabs">

						<div className={`donate-tabs__nav step-${donationStep}`}>
							<div
								className={`donate-tabs__nav__item ${(currentTab === ETabs.DONATE) ? "is-active" : ""}`}
								onClick={() => updateCurrentTab(ETabs.DONATE)}
							>
								<h5>Upload</h5>
							</div>
							<div
								className={`donate-tabs__nav__item ${(currentTab === ETabs.FAQ) ? "is-active" : ""}`}
								onClick={() => updateCurrentTab(ETabs.FAQ)}
							>
								<h5>FAQ</h5>
							</div>
						</div>

						<div className="donate-tabs__content">
							<div
								className={`donate-tabs__content__item donate-tabs__content__item--form ${(currentTab === ETabs.DONATE) ? "is-active" : ""}`}
							>
								
							</div>
							<div
								className={`donate-tabs__content__item donate-tabs__content__item--faq ${(currentTab === ETabs.FAQ) ? "is-active" : ""}`}
							>
								<FaqComponent setModalContent={setModalContent}/>
							</div>
						</div>


					</section>
				</Container>
			</div> */}

		</React.Fragment>
	);
}

Stories.defaultProps = {};

export default Stories;
