import * as React from "react";
import { Col} from "react-bootstrap"
import Cropper from 'cropperjs';
import {Dispatch, MutableRefObject, SetStateAction, useEffect, useRef} from "react";

interface IProps {
	image: File;
	submitBase64: (base64: string) => void;
	initialCropBoxData?: Cropper.CropBoxData & { containerWidth: number; };
	setCropBoxData: Dispatch<SetStateAction<Cropper.CropBoxData & { containerWidth: number; } | undefined>>;
}



const ImageCrop: React.FC<IProps> = (props: IProps): JSX.Element => {
	const {image, submitBase64, initialCropBoxData, setCropBoxData} = props;
	const imageRef: MutableRefObject<any> = useRef();
	let cropper: Cropper;

	useEffect(() => {
		if (imageRef.current) {
			// eslint-disable-next-line react-hooks/exhaustive-deps
			cropper = new Cropper((imageRef.current as HTMLImageElement), {
				
				// autoCropArea: 1,
				aspectRatio: 1,
				zoomable: true,
				movable: false,
				rotatable: false,
				scalable: false,
				zoomOnTouch: true,
				
				
				// crop: onCrop,
				ready: onCropReady,
				cropend: onCropEnd,
			});
		}
	}, [imageRef]);

	// function onCrop(event: Cropper.CropEvent) {
	// 	console.log(event.detail.x);
	// 	console.log(event.detail.y);
	// 	console.log(event.detail.width);
	// 	console.log(event.detail.height);
	// 	console.log(event.detail.rotate);
	// 	console.log(event.detail.scaleX);
	// 	console.log(event.detail.scaleY);
	// }

	function onCropReady(): void {
		const containerData = cropper.getContainerData();
		if (initialCropBoxData) { // Custom crop area passed in. Probably from the edit page.
			// Calculate new crop box based on container size ratio
			const ratio = containerData.width / initialCropBoxData.containerWidth;
			const cropBoxData: Cropper.CropBoxData = {
				top: initialCropBoxData.top * ratio,
				left: initialCropBoxData.left * ratio,
				width: initialCropBoxData.width * ratio,
				height: initialCropBoxData.height * ratio,
			}

			cropper.setCropBoxData(cropBoxData);
		} else { // Initial crop area
			const size = Math.min(containerData.width, containerData.height);
			const top = (containerData.height / 2) - (size / 2);
			const left = (containerData.width / 2) - (size / 2);
			const cropBoxData: Cropper.CropBoxData = {
				top: top,
				left: left,
				width: size,
				height: size,
			}

			cropper.setCropBoxData(cropBoxData);
		}
		setTimeout(onCropEnd, 1000);
	}

	function onCropEnd(): void {
		const cropBoxData = cropper.getCropBoxData();
		const containerData = cropper.getContainerData();
		setCropBoxData({...cropBoxData, containerWidth: containerData.width});
		submitBase64(getCroppedBase64());
	}

	function getCroppedBase64(): string {
		return cropper.getCroppedCanvas({
			// width: 500,
		}).toDataURL('image/jpeg');
	}

	return (
		<Col md={4}>
		<div className="image-crop-parent">
			<img className="img-fluid" ref={imageRef} src={URL.createObjectURL(image)} alt="crop tool"/>
		</div>
		</Col>
		
	);
}

export default ImageCrop;
