import * as React from "react";
import {Col, Container, Row} from "react-bootstrap";
import back2 from "../static/Art/back-2.png";
import back3 from "../static/Art/back-3.png";
import pm22topM from "../static/About/pm22-top-m.png";
import pm22btmM from "../static/About/pm22-btm-m.png";
import Map from "../static/Art/map.svg";
import shareStory1 from "../static/share-story-1.png";
import shareStory2 from "../static/share-story-2.png";
import downloadStory from "../static/pixel-dl-instagram-story.png";
import downloadPost from "../static/pixel-dl-instagram-post.png";
import heroHearts1 from "../static/thank-you-hearts-top-left.svg";
import heroHearts2 from "../static/thank-you-hearts-bottom-right.svg";

const StoriesThankYou: React.FC = (): JSX.Element => {

	return (
		<React.Fragment>

			<section className="donate-thank-you-hero">
				<img src={heroHearts1} alt="hearts"  className="donate-thank-you-hero__hearts-1" />
				<img src={heroHearts2} alt="hearts"  className="donate-thank-you-hero__hearts-2" />

				<Container>

					<h1>Thank You For Your Support!</h1>

					<p>Your pixel will join the thousands of others who have who have also decided to take a stand for
						mental health as part of our interactive mural mosaic coming March 2022.</p>

				</Container>

				<a className="next-steps" href="#spread-the-word">
					<span>NEXT STEPS</span>
					<span className="fal fa-arrow-down"/>
				</a>
			</section>
			<section id="spread-the-word" className="spread-the-word">
				<Container>

					<h2>Help Us Spread the Word!</h2>
					<p>Show you support for Pixel Moments to help us create awareness for mental health and raise funds
						for the St. Paul's Foundation.</p>
				</Container>
			</section>

			<section className="thank-you-next-steps">
				<Container>
					<Row className="d-flex align-items-center py-5 border-bottom">
						<Col xs={12} md={5} className="text-center py-3">
							<img src={shareStory1} alt="I Donated. Now it's your turn" />
						</Col>
						<Col xs={12} md={7} className="py-3">
							<h2>1</h2>
							<h2 className="mb-3">Download the Supporter Graphic</h2>
							<ul>
								<li>Choose from either the Instagram story or post graphic</li>
								<li>Save the graphic to your photo gallery</li>
							</ul>
						</Col>
					</Row>
					<Row className="d-flex align-items-center flex-md-row-reverse py-5 border-bottom">
						<Col xs={12} md={5} className="text-center py-3">
							<img src={shareStory2} alt="I Donated. Now it's your turn" />
						</Col>
						<Col xs={12} md={7} className="py-3">
							<h2>2</h2>
							<h2 className="mb-3">Share To Your Instagram Account</h2>
							<ul>
								<li>Open Instagram, and select the graphic from your photo gallery</li>
									<li>Post to your Instagram Feed or Story</li>
							</ul>
						</Col>
					</Row>
					<Row className="d-flex align-items-center py-5 border-bottom">
						<Col xs={12} md={5} className="text-center py-3">
							<img src={shareStory2} alt="I Donated. Now it's your turn" />
						</Col>
						<Col xs={12} md={7} className="py-3">
							<h2>3</h2>
							<h2 className="mb-3">Tag @PixelDonate and @HelpStPauls</h2>
							<ul>
								<li>Tag us at @PixelDonate and @HelpStPauls to connect with us and share your stories!</li>
							</ul>
							<div className="pt-3">
								<a href={downloadPost} className="btn-dl-instagram btn-dl-instagram--blue" download>
									Download IG Post
								</a>
								<a href={downloadStory} className="btn-dl-instagram btn-dl-instagram--green" download>
									Download IG Story
								</a>
							</div>
						</Col>
					</Row>
				</Container>
			</section>


			<section className="artwork-about">

				<div className="d-none d-lg-block art-back-2">
					<img src={back2} alt="London Drugs Vancouver" className=""/>
				</div>

				<div className="d-none d-lg-block art-back-3">
					<img src={back3} alt="London Drugs Vancouver" className=""/>
				</div>
				<div className="d-block d-lg-none pm22-about-top-m">
					<img src={pm22topM} alt="London Drugs Vancouver" className=""/>
				</div>
				<div className="d-block d-lg-none pm22-about-btm-m">
					<img src={pm22btmM} alt="London Drugs Vancouver" className=""/>
				</div>


				<Container>

					<Row className=" pt-md-5">
						<Col xs={12} md={12} lg={5} className="mb-5 art-image">
							<img src={Map} alt="Mural Location Map"/>
						</Col>
						<Col xs={12} md={12} lg={7} className="art-location">
							<div>
								<h3>Location of the Mural</h3>
							</div>
							<div>
								<p>
									Pixel Moments' 2022 mosaic artwork is comprised of all our donor selfies from this year's campaign. A purely digital effort, anyone, anywhere could participate. Today, the art is in its physical location at Georgia & Granville, celebrating how our community came together to support mental health.
								</p>
							</div>

						</Col>
					</Row>

				</Container>
			</section>



		</React.Fragment>
	);
}

StoriesThankYou.defaultProps = {};

export default StoriesThankYou;
