import * as React from "react";
import {Container} from "react-bootstrap";

import FaqComponent from "../components/FaqComponent";

const FAQ: React.FC = (): JSX.Element => {

	return (
		<main className="basic-page bg-white">


			<section className="basic-page--content">

				

				<Container>
					

					

					<FaqComponent/>

					
				</Container>
			</section>
		</main>
	);
}


export default FAQ;
