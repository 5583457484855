/**
 * @brainhubeu/react-carousel
 * https://brainhubeu.github.io/react-carousel/docs/gettingStarted
 */

import * as React from "react";
import Carousel, {slidesToShowPlugin} from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import {useEffect, useState} from "react";
import carouselArrowLeft from "../static/carousel-arrow-left.svg";
import carouselArrowRight from "../static/carousel-arrow-right.svg";
import {Container, Row, Col} from "react-bootstrap";
import storiesCarouselSlides, {IStoriesCarouselSlide} from "../data/storiesCarouselSlides";

import Sec3btm2 from "../static/Home/quotes.svg";

const StoriesCarouselDesktop: React.FC = (): JSX.Element => {
	const [slideIndex, setSlideIndex] = useState<number>(0);
	const [detailsStatus, setDetailsStatus] = useState<boolean>(false);
	const [slides, setSlides] = useState<IStoriesCarouselSlide[]>([]);

	useEffect(() => {
		// Bug with rerender on dom change with "infinite" option
		// Refer https://github.com/brainhubeu/react-carousel/issues/636
		// Need to implement own "pre" and "post" class
		const firstSlide = storiesCarouselSlides[0];
		const lastSlide = storiesCarouselSlides[storiesCarouselSlides.length - 1];
		setSlides([lastSlide, ...storiesCarouselSlides, firstSlide]);
		setSlideIndex(1);
	}, []);

	function goToSlide(index: number): void {
		if (Number.isInteger(index) && index >= 0 && index < slides.length) {
			setSlideIndex(index);
		}
	}

	function goToPrevSlide(): void {
		// Custom infinite requires custom implementation of first & last slide, see above useEffect
		if (slideIndex > 1) {
			goToSlide(slideIndex - 1);
		} else {
			goToSlide(slides.length - 2);
		}
	}

	function goToNextSlide(): void {
		// Custom infinite requires custom implementation of first & last slide, see above useEffect
		if ((slideIndex + 2) < slides.length) {
			goToSlide(slideIndex + 1);
		} else {
			goToSlide(1);
		}
	}

	function carouselSlide(slide: IStoriesCarouselSlide, index: number): JSX.Element {
		return (
			<div key={`slide-${index}`} className="component-stories-desktop-inner-container">
				
				<div className="component-stories-desktop-inner-container-img">
				
					<iframe width="541" height="323" src={slide.video} title="YouTube video player"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" ></iframe>
				</div>
				<div className="component-stories-desktop-inner-container-text">
					

					<h3 >{slide.title}</h3>
					<p >{slide.description}</p>
				</div>
			</div>
		)
	}

	return (
		<React.Fragment>
			

			<div className={`stories-carousel-desktop  ${(detailsStatus) ? "is-details-active" : ""}`}>

			<Container className="steps-container-2-">
				<Row>
					<Col xs={12} md={12}  className="py-3 component-stories-desktop">
					
						<div className="compenent-stories-desktop-heading">
							<div className="color-white pm-header">
								<h2 className="">Raising Awareness for Mental Health</h2>
								<p>Pixel Moments brings mental health to the forefront. Together, let’s raise more awareness, foster empathy, and encourage action. </p>
				
							</div>
						</div>
						<div className="component-stories-desktop-container">
						<Carousel
						draggable={false}
						value={slideIndex}
						onChange={goToSlide}
						plugins={[
							"fastSwipe",
							// 'infinite',
							// 'arrows',
							// 'centered',
							
						]}
					>
						{slides.map(carouselSlide)}
					</Carousel>
						</div>

						<div className="component-stories-desktop-testi">
						<div className="d-none d-lg-block back-sec-3-4-clone">
							<img src={Sec3btm2} alt="London Drugs Vancouver" className=""/>
						</div>
							<div className="container-inner-testi ">
								<p>“St. Paul’s Mental Health program understands that mental wellness is a right for everyone. Mental illness does not discriminate and has no boundaries.  Mental illness impacts our children, our youth and our elders.  Over my 20 years working at St. Paul’s Hospital,  I have seen the commitment of our staff to not only treat mental illness but also to advocate for  fair, transparent  and  non-stigmatized care for all members of our community.”</p>
								<div className="author-testi">
								<p>Harpreet Chauhan,  MD, FRCPC</p>
								<p>Geriatric Psychiatrist</p>
								<p>Department Head,  Psychiatry,  Providence Health Care</p>
								</div>
								
							</div>	
						</div>	

						
						
						
					</Col>
				</Row>
			</Container>

				
				<button
					onClick={goToPrevSlide}
					// disabled={(slideIndex === 0)}
					className="hideen"
				>
					<i className="far fa-angle-left"/>
					<img src={carouselArrowLeft} alt="carousel prev"/>
				</button>
				<button
					onClick={goToNextSlide}
					// disabled={(slideIndex + 1) === slides.length}
					className="hideen"
				>
					<i className="far fa-angle-right"/>
					<img src={carouselArrowRight} alt="carousel next"/>
				</button>
			</div>
		</React.Fragment>
	);
}

export default StoriesCarouselDesktop;
