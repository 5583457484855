import React, {useEffect} from 'react';
import './styles/index.scss';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import About from "./pages/About";
import Homepage from "./pages/Homepage";
import Stories from "./pages/Stories";
import Artwork from "./pages/Artwork";
import Header from "./components/Header";
import Footer from "./components/Footer";
import StoriesThankYou from "./pages/StoriesThankYou";
import Donate from "./pages/Donate";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Sponsors from "./pages/Sponsors";
import FAQ from "./pages/FAQ";
import PageNotFound from "./pages/PageNotFound";
import TermsAndConditions from './pages/TermsAndConditions';
import TagManager from 'react-gtm-module';
import backendAdapters from 'backend-adapters';

const App: React.FC = () => {
	useEffect(() => {
		TagManager.initialize({gtmId: backendAdapters.variables.analytics.tagManager as string});
	}, []);

	// Redirect to HTTPS if not secured
	if (process.env.NODE_ENV === "production" && document.location.protocol === "http:") {
		const pathname = document.location.pathname;
		window.location.replace(`https://pixelmoments.org${pathname}`);
	}

	return (
		<BrowserRouter>
			<Header/>

			<main>

				<Switch>
					<Route path="/" exact>
						<Homepage/>
					</Route>
					<Route path="/about" exact>
						<About/>
					</Route>
					<Route path="/sponsors" exact>
						<Sponsors/>
					</Route>
					<Route path="/donate" exact>
						<Donate/>
					</Route>
					<Route path="/pixel-upload" exact>
						<Stories/>
					</Route>
					<Route path="/pixel-upload/add/:id" exact>
						<Stories/>
					</Route>
					<Route path="/pixel-upload/thank-you" exact>
						<StoriesThankYou/>
					</Route>
					<Route path="/pixel-upload/thank-you/:name" exact>
						<StoriesThankYou/>
					</Route>
					<Route path="/artwork" exact>
						<Artwork/>
					</Route>
					<Route path="/faq" exact>
						<FAQ/>
					</Route>
					<Route path="/privacy-policy" exact>
						<PrivacyPolicy/>
					</Route>
					<Route path="/terms-and-conditions" exact>
						<TermsAndConditions/>
					</Route>
					<Route path="/plaza" exact component={() => {
						window.location.replace("https://lngstudios.8thwall.app/pixelmoments/");
						return null;
					}}/>
					<Route>
						<PageNotFound/>
					</Route>

				</Switch>
			</main>

			<Footer/>
		</BrowserRouter>
	);
}

export default App;
