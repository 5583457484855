import * as React from "react";
import DonateSteps from "../components/DonateSteps";
import WhatIsPixelMoments from "../components/WhatIsPixelMoments";
import WhatIsPixelMomentsMobile from "../components/WhatIsPixelMomentsMobile";
import StoriesCarouselMobile from "../components/StoriesCarouselMobile";
import StoriesCarouselDesktop from "../components/StoriesCarouselDesktop";
import PreviousMural from "../components/PreviousMural";



import {Container} from "react-bootstrap";
import InstagramWidget from "../components/InstagramWidget";

import HeroTitle from "../static/Home/hero-title.svg"
import Sec1btm from "../static/Home/sec-1-btm.png";
import Sec1top from "../static/Home/sec-1-top.png";

import Sec2top from "../static/Home/sec-2-top.png";
import Sec2btm from "../static/Home/sec-2-btm.png";
import pm22btm from "../static/About/pm22-btm.svg";

import Sec3topa from "../static/Home/sec-3-top1.png";
import Sec3btm from "../static/Home/sec-3-btm.svg";

import Sec3btm2 from "../static/Home/quotes.svg";

import Sec3topb from "../static/Home/sec-3-top.svg";

import Sec3mtopa from "../static/Home/storiesmt.png";
import Sec3mbtm from "../static/Home/storiesmc.svg";
import Sec3mtopb from "../static/Home/storiesmb.svg";

import Whatismobielback from "../static/Home/what-is-mobile.png";

const Homepage: React.FC = (): JSX.Element => {

	return (
		<React.Fragment>

			<section className="hero hero-home">
				{/*<img src={VRDemo} alt="London Drugs Vancouver" className="hero__img"/>*/}
				{/* <video playsInline loop muted autoPlay poster={VRDemo}>
					<source
						src="https://pixel-moments.s3-us-west-1.amazonaws.com/assets/Pixelmoments_How_To_AR_compressed.mp4"
						type="video/mp4"
					/>
				</video> */}
				
				<div className="d-none d-lg-block back-sec-1-1">
				<img src={Sec1btm} alt="London Drugs Vancouver" className=""/>
				</div>
				<div className="d-none d-lg-block back-sec-1-2">
				<img src={Sec1top} alt="London Drugs Vancouver" className=""/>
				</div>
					
				<div className="hero__overlay home-overlay">
					<div>
						{/*<CountdownTimer/>*/}
						<div className="hero__overlay__tagline">
						<img src={HeroTitle} alt="London Drugs Vancouver" className=""/>
						</div>
						<h4 className="py-5 text-white hero__overlay__text  override-work-sans">
							What a success! Once again, Pixel Moments engaged our community in a fun, easy initiative
							that brought mental health issues to the forefront. Pixel by pixel, you made a difference.
						</h4>
						<div className="py-5 w-100">
							{/* hero__overlay__cta class is for Tag Manager tracking */}
							
							
							<a href="https://secure3.convio.net/sphf/site/Donation2?df_id=2620&2620.donation=form1&mfc_pref=T" className="btn btn--stack " ><span>Donate Now</span></a>
						</div>
						
					</div>
				</div>
			</section>
			<section className="homepage-whatis">
				<div className="d-none d-lg-block ">
					<div className="d-none d-lg-block back-sec-2-1">
						<img src={Sec2top} alt="London Drugs Vancouver" className=""/>
					</div>
					<div className="d-none d-lg-block back-sec-2-2">
					<img src={Sec2btm} alt="London Drugs Vancouver" className=""/>
					</div>
					<div className="d-none d-lg-block pm22-about-btm">
					<img src={pm22btm} alt="London Drugs Vancouver" className=""/>
					</div>
					<WhatIsPixelMoments/>
				</div>
				<div className="d-block d-lg-none what-is-mobile-back">
					<img src={Whatismobielback} alt="London Drugs Vancouver" className=""/>
					</div>
				<div className="d-block d-lg-none ">
					
				
					<WhatIsPixelMomentsMobile/>
				</div>
				
			</section>

			{/* <section id="homepage-location" className="homepage-location">
				<div className="absolute-pixel absolute-pixel--1">
					<div className="pixel pixel--1"/>
					<div className="pixel pixel--2"/>
				</div>

				<LocationCarousel/>
			</section> */}

			{/* <section className="homepage-streetview">
				<img src={VRDemo} className="homepage-streetview__image" alt="VR Demo"/>
			</section> */}

			<section id="homepage-story-card" className="homepage-story-card-1">
				

				

				<div className="homepage-story-carousel ">
					<div className="d-none d-lg-block">
						<div className="d-none d-lg-block back-sec-3-2">
							<img src={Sec3topa} alt="London Drugs Vancouver" className=""/>
						</div>
						<div className="d-none d-lg-block back-sec-3-1">
						<img src={Sec3topb} alt="London Drugs Vancouver" className=""/>
						</div>
						<div className="d-none d-lg-block back-sec-3-3-clone">
							<img src={Sec3btm} alt="London Drugs Vancouver" className=""/>
						</div>
						
						<StoriesCarouselDesktop/>
					</div>
					<div className="d-block d-lg-none">

						<div className="d-block d-lg-none back-sec-m-3-2">
							<img src={Sec3mtopa} alt="London Drugs Vancouver" className=""/>
						</div>
						<div className="d-block d-lg-none back-sec-m-3-1-clone-home">
						<img src={Sec3mtopb} alt="London Drugs Vancouver" className=""/>
						</div>
						<div className="d-block d-lg-none back-sec-m-3-3">
							<img src={Sec3mbtm} alt="London Drugs Vancouver" className=""/>
						</div>

						<StoriesCarouselMobile/>
					</div>
				</div>
			</section>

			<section id="homepage-story-card" className="d-block d-lg-none understory-sec">
				

				

				<div className="homepage-story-carousel  homepage-under-story">

				
					
					<div className="d-block d-lg-none under-story">

						<div className="d-block d-lg-none back-sec-m-3-2-clone">
							<img src={Sec3btm2} alt="London Drugs Vancouver" className=""/>
						</div>
						
						<div className="container-inner-testi container-inner-testi-m">
							<p>"St. Paul's Mental Health program understands that mental wellness is a right for everyone. Mental illness does not discriminate and has no boundaries.  Mental illness impacts our children, our youth and our elders.  Over my 20 years working at St. Paul's Hospital,  I have seen the commitment of our staff to not only treat mental illness but also to advocate for  fair, transparent  and  non-stigmatized care for all members of our community."</p>
							<div className="author-testi">
								<p>Harpreet Chauhan,  MD, FRCPC</p>
								<p>Geriatric Psychiatrist</p>
								<p>Department Head,  Psychiatry,  Providence Health Care</p>
							</div>
								
						</div>

						
					</div>
				</div>
			</section>


			<section className="homepage-donate">
				
				<DonateSteps/>
			</section>

			<section className="homepage-previousmural bg-white">
				<PreviousMural/>
			</section>

			<section className="homepage-instagram bg-white">
				<Container>
					<div className="left-header pm-header ">
						<h2 className="insta-header">Follow Us On Instagram</h2>
						<p className="mb-5">Follow along 
						<a
							href="https://www.instagram.com/pixeldonate/"
							target="_blank"
							rel="noopener noreferrer"
							className="text-orange"
						> @PixelDonate</a> for updates on when and where your pixel will be shared. Use <span className="text-orange"> #PixelDonate</span> to connect with us and other Pixel Moments supporters.
						</p>
					</div>
					<InstagramWidget/>
				</Container>

				
			</section>

		</React.Fragment>
	);
}


export default Homepage;
