

export interface IStoriesCarouselSlide {
	video: string;
	title: string;
	description: string;
}

const storiesCarouselSlides: IStoriesCarouselSlide[] = [
	{
		title: "Dwayne’s Story",
		description: "We all know someone who has struggled with mental health issues. In fact, by age 40, half of Canadians have experienced a mental health issue.* For Dwayne, the mental health resources at St. Paul’s helped his daughter find immense improvement in her well-being after many years of struggle.",
		video: "https://www.youtube.com/embed/RcSf6q2zMs4",
	}
];

export default storiesCarouselSlides;
