import * as React from "react";
import {Link, withRouter, RouteComponentProps} from "react-router-dom";
import pixelMomentsLogo from "../static/pixel-moments-logo.svg";
import pixelMomentsLogoW from "../static/pixel-moments-logo-white.svg";
import {Container} from "react-bootstrap";
import {useEffect, useState} from "react";


import mnavb from "../static/nav/mnav-b.svg";
import mnavl from "../static/nav/mnav-l.svg";
import mnavr from "../static/nav/mnav-r.svg";
interface IProps extends RouteComponentProps {
}

enum EHeaderLogo {
	DEFAULT,
	ST_PAULS,
}

const Header: React.FC<IProps> = (props: IProps): JSX.Element => {
	const {location} = props;
	const [scrollActive, setScrollActive] = useState<boolean>(false);
	const [colorNav, setColorNav] = useState<string>('component');
	const [menuActive, setMenuActive] = useState<boolean>(false);
	const [logo, setLogo] = useState<EHeaderLogo>(EHeaderLogo.DEFAULT);

	useEffect(() => {
		// Close menu when changing pages
		setMenuActive(false);

		
		document.body.classList.remove('scroll-none');
		  


		setColorNav(location.pathname === "/about"? 'light': 'component')
		// Scroll to top when changing pages
		window.scrollTo(0, 0);

	}, [location]);

	useEffect(() => {
		if (menuActive) {
			// Logo is always the default one when menu is active
			setLogo(EHeaderLogo.DEFAULT);
		} else {
			// alternative logo on donation pages
			let pathname = location.pathname;
			// Remove trailing slash from pathname
			if (pathname[pathname.length - 1] === "/") {
				pathname = pathname.substring(0, pathname.length - 1);
			}
			if (pathname === "/donate" || pathname.includes("/pixel-upload")) {
				setLogo(EHeaderLogo.ST_PAULS);
			} else {
				setLogo(EHeaderLogo.DEFAULT);
			}
		}
	}, [menuActive, location]);

	function onScroll() {
		// Initial header position is different for mobile / desktop
		const scrollStart: number = (window.innerWidth < 768) ? 40 : 65;

		// Add active scroll class
		if (window.scrollY > scrollStart) {
			setScrollActive(true);
		} else {
			setScrollActive(false);
		}
	}


	const toogleMenu = () =>{
		setMenuActive(!menuActive)
		
		if (!menuActive) {
			document.body.classList.add('scroll-none');
		  } else {
			document.body.classList.remove('scroll-none');
		  }
		
	}


	useEffect(() => {
		document.addEventListener('scroll', onScroll);

		return () => {
			document.removeEventListener('scroll', onScroll);
		}
	}, []);

	return (
		<header
			className={`${colorNav}-header   ${(scrollActive && logo === EHeaderLogo.DEFAULT) && "is-scroll-active"} ${(logo === EHeaderLogo.ST_PAULS) && "is-component-header"}  ${menuActive &&  "is-menu-active"}`}
		>
			<div className={"d-block d-lg-none nav-b " + (menuActive ? 'show-shape' : 'hide-shape')}>
				<img src={mnavb} alt="London Drugs Vancouver" className=""/>
				</div>
				<div className={"d-block d-lg-none nav-l " + (menuActive ? 'show-shape' : 'hide-shape')}> 
				<img src={mnavl} alt="London Drugs Vancouver" className=""/>
				</div>
				<div className={"d-block d-lg-none nav-r " + (menuActive ? 'show-shape' : 'hide-shape')}>
				<img src={mnavr} alt="London Drugs Vancouver" className=""/>
				</div>
				<div className={"d-block d-lg-none nav-i " + (menuActive ? 'show-shape' : 'hide-shape')}>
						<Container className="text-center social-parent">
						<a className="social-image-link" href="https://twitter.com/Pixel_Donate" target="_blank"
						   rel="noopener noreferrer">
							<i className="fab fa-twitter"/>
						</a>
						<a className="social-image-link" href="https://www.instagram.com/PixelDonate" target="_blank"
						   rel="noopener noreferrer">
							<i className="fab fa-instagram"/>
						</a>
						<a className="social-image-link" href="https://www.facebook.com/pixelmoments.org"
						   target="_blank" rel="noopener noreferrer">
							<i className="fab fa-facebook"/>
						</a>
						</Container>
						</div>

			<Container className="header-parent">
				<div className={`${colorNav}-header__logo`}>
					<Link to="/">
						{(logo === EHeaderLogo.ST_PAULS) ? (
							<img src={pixelMomentsLogoW} className="component-header__logo__default"
							     alt="Pixel Moments in support of St Pauls Foundation"/>
						) : (
							(location.pathname === "/about" && !scrollActive && !menuActive) ? (<img src={pixelMomentsLogo} className="component-header__logo__default"
							alt="Pixel Moments"/>): (<img src={pixelMomentsLogoW} className="component-header__logo__default"
							alt="Pixel Moments"/>) 
							
						)}
					</Link>
				</div>
				<div className={`${colorNav}-header__hamburger`} >
					<button
						className={`${colorNav}-header__hamburger__icon`} 
						onClick={() =>  toogleMenu()}
					/>
				</div>
				<div className={`${colorNav}-header__menu`}   >
					<ul>
						<li className={`${colorNav}-header__menu__home ${(location.pathname === "/") && "is-active"}`}>
							<Link to="/">Home</Link>
						</li>
						<li className={(location.pathname === "/about") ? "is-active" : ""}>
							<Link to="/about">About</Link>
						</li>
						<li className={(location.pathname === "/artwork") ? "is-active" : ""}>
							<Link to="/artwork">The Art</Link>
						</li>
						<li className={`hr-bottom  ${(location.pathname === "/sponsors") && "is-active"}`}>
							<Link to="/sponsors">Sponsors</Link>
						</li>
						<li className={`${colorNav}-header__menu__donate d-none d-lg-block`} >
							{/* component-header__menu__donate__link class is for Tag Manager tracking */}
							<a href="https://secure3.convio.net/sphf/site/Donation2?df_id=2620&2620.donation=form1&mfc_pref=T" className={`${colorNav}-header__menu__donate__link`} >Donate Now</a>
						</li>
							
						<li className={`${colorNav}-header__menu__donate d-block d-lg-none`} >
							{/* component-header__menu__donate__link class is for Tag Manager tracking */}
							<a href="https://secure3.convio.net/sphf/site/Donation2?df_id=2620&2620.donation=form1&mfc_pref=T" className="btn btn--stack btn-nav-cust" ><span>Donate Now</span></a>
						</li>

						
					</ul>
				</div>
			</Container>
		</header>
	);
}

export default withRouter(Header);
