import * as React from "react";
import {Col, Container, Row} from "react-bootstrap";
import donate from "../static/animations/donate.gif"
import upload from "../static/animations/upload.gif"
import share from "../static/animations/share.gif"

import {Link} from "react-router-dom";

interface donationImageElement {
	header: string;
	image: string;
	imageContainerClasses?: string;
	imageStyle?: object;
	alt: string;
	description: string;
	descriptionClasses?: string,
}

const donationImageArray: donationImageElement[] = [
	{
		header: "Step 1 Donate",
		image: donate,
		imageContainerClasses: "donate-step__1",
		alt: "step 1",
		description: "Make a minimum $5 donation directly from your smartphone. All proceeds go to St. Paul’s Foundation to support mental health initiatives.",
		descriptionClasses: "py-md-5",
	},
	{
		header: "Step 2 Upload",
		image: upload,
		imageContainerClasses: "donate-step__2",
		alt: "step 2",
		description: "Snap a selfie or upload an image that will become your pixel in the 2022 Pixel Moments mural.",
		descriptionClasses: "py-md-5",
	},
	{
		header: "Step 3 Share",
		image: share,
		imageContainerClasses: "donate-step__3",
		alt: "step 3",
		description: "Share your pixel on social media and include @pixeldonate to spread the word!",
		descriptionClasses: "pt-md-5",
	},
]


function renderElement(content: any, index: number): JSX.Element {
	return (
		<Col xs={12} md={4} key={`donation-step-${index}`} className="py-3">
			<div className="component-donate-steps">
				
				<div
					className={`component-donate-steps-image ${content.imageContainerClasses && content.imageContainerClasses}`}>
					<img style={content.imageStyle} src={content.image} alt={content.alt}/>
				</div>
				<h4 className="component-donate-steps-header">{content.header}</h4>
				<h5 className={`component-donate-steps-description ${content.descriptionClasses ? content.descriptionClasses : "py-3"}`}>{content.description}</h5>
			</div>
		</Col>
	);
}

const DonateSteps: React.FC = (): JSX.Element => {

	return (
		<Container>

			<div className="left-header pm-header">
				<h2 className="">How Do I Participate?</h2>
				{/* <p className="text-left  py-5">For a minimum donation of $5, you can upload one selfie or
					photo of yourself. Your photo will become part of the mural mosaic as it is updated and future Pixel
					Moments public art installations around British Columbia. Pixel by pixel, watch as our collective
					photos morph into impactful public art pieces that symbolize the unified strength and support of our
					community.</p> */}
			</div>

			<Container className="text-center steps-container">
				<Row>
					{donationImageArray.map(renderElement)}
				</Row>
			</Container>
			<div className="py-5  text-center">
				<Link to="/faq" className={"btn btn--blue-outline"}>FAQ</Link>
			</div>
		</Container>
	);
}

DonateSteps.defaultProps = {};

export default DonateSteps;
